import React from 'react';
import { css } from 'emotion';

const Blur = props => {
  return (
    <React.Fragment>
      <svg
        version="1.1"
        className={css`
          display: none;
        `}
      >
        <defs>
          <filter id="blur-filter">
            <feGaussianBlur stdDeviation="3" />
          </filter>
        </defs>
      </svg>
      <div
        className={css`
          position: relative;
          width: ${props.width};
        `}
      >
        <div
          className={css`
            ${props.blur
              ? `
              filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
              -webkit-filter: url(#blur-filter);
              filter: url(#blur-filter);
              -webkit-filter: blur(3px);
              filter: blur(3px);
              `
              : ''};
          `}
        >
          {props.children}
        </div>
        {props.overlay}
      </div>
    </React.Fragment>
  );
};

Blur.defaultProps = {
  width: '50%',
};
export default Blur;
