import produce from 'immer';
import actionTypes from '../actions/actionTypes';

const initialState = null;

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.PAYOUT_DATA_SUCCESS:
      return action.payload;
    case actionTypes.LOGOUT_SUCCESS:
      return null;
    default:
  }
}, initialState);
