import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from '../';

/**
 * Functional React component for Layout Component: header/footer page wrapper/layout
 * @function
 * @param {object} props - React props: children JSX.Element <PageComponent>
 * @returns {JSX.Element}
 */

const Layout = ({ children, title }) => (
  <div>
    <Nav title="Fitplan" />
    {children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Layout;
