import React from 'react';
import format from 'date-fns/format';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { requestPayouts } from '../../actions/asyncActions';
import {
  dailyTrialsSelector,
  dailyTrialsDifference,
  monthlySubsSelector,
  monthlySubsDifference,
} from '../../selectors';

import {
  DashboardSubscribersBlocks,
  UserPayoutBlock,
  GridPayoutBlocks,
  LinkToContact,
} from '../';
import './DesktopView.scss';

class DesktopView extends React.Component {
  componentDidMount() {
    if (!this.props.hasData) {
      this.props.requestPayouts();
    }
  }

  render() {
    if (!this.props.hasData) return null;

    return (
      <div className="desktop__page" data-test="desktop-page">
        <h1 className="desktop__title" data-test="title">
          {this.props.title}
        </h1>
        <h4 className="subscribers__blocks-month" data-test="block-month">
          {this.props.month}
        </h4>
        <DashboardSubscribersBlocks
          trialUsers={this.props.trialUsers}
          subscribers={this.props.subscribers}
          trialUsersDifference={this.props.trialUsersDifference}
          subscribersDifference={this.props.subscribersDifference}
        />

        <UserPayoutBlock />
        <span
          className={css`
            margin-top: -15px;
            margin-bottom: 15px;
          `}
          data-test="user-payout-asterisk"
        >
          *based on current numbers
        </span>
        <h4 className="subscribers__blocks-payout" data-test="block-payout">
          PAYOUT HISTORY
        </h4>
        <GridPayoutBlocks />
        <div
          className={css`
            @media (min-width: 1199px) {
              display: none;
            }
          `}
        >
          <LinkToContact
            firstName="Accounting"
            subject="finance"
            question="Questions about your payouts?"
            data-test="block-contact"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  trialUsers: dailyTrialsSelector(state),
  trialUsersDifference: dailyTrialsDifference(state),
  subscribers: monthlySubsSelector(state),
  subscribersDifference: monthlySubsDifference(state),
  hasData: state.data && Object.keys(state.data).length > 0,
});

const mapDispatchToProps = {
  requestPayouts,
};

DesktopView.defaultProps = {
  title: 'Dashboard',
  month: format(new Date(), 'MMMM'), // current month
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesktopView);
