import React from 'react';
import { css } from 'emotion';

const ComingSoon = props => (
  <svg
    className={css`
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    `}
    width="162px"
    height="92px"
    viewBox="0 0 95 51"
  >
    <title>coming soon</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <filter
        x="-29.9%"
        y="-74.2%"
        width="159.7%"
        height="248.4%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      id="iPhone-v2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="athlete-dashboard-summary-soon"
        transform="translate(-208.000000, -335.417030)"
      >
        <g
          id="coming-soon"
          filter="url(#filter-1)"
          transform="translate(216.700000, 343.792223)"
        >
          <polygon
            id="Rectangle-3"
            fillOpacity="0.3"
            fill="#0076F9"
            points="0.804800907 0.180705734 76.5848009 0.180705734 76.5848009 26.0766904 76.5848009 30.6107057 0.804800907 30.6107057"
          />
          <polygon
            id="Rectangle-3"
            fill="#0076F9"
            points="10.2773009 0.361411468 76.5848009 0.361411468 67.1123009 30.7914115 0.804800907 30.7914115"
          />
          <g
            id="Group"
            transform="translate(3.694801, 3.076411)"
            fill="#FFFFFF"
            fontFamily="Barlow-Regular, Barlow"
            fontWeight="normal"
          >
            <text id="Coming-Soon">
              <tspan x="0.194800907" y="24.3614115" fontSize="12">
                Coming Soon
              </tspan>
            </text>
            <text id="Expected-Payouts">
              <tspan x="3.19480091" y="8.36141147" fontSize="8">
                {props.feature}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ComingSoon;
