import React from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { css } from 'emotion';
import { requestPayouts } from '../../actions/asyncActions';
import { payoutBlocksData } from '../../selectors';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import './payout-blocks.scss';

const numberWithCommas = x => {
  if (!x) return null;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const MonthPayoutBlockDesktop = props => {
  const { data } = props;
  return (
    <div
      className="payout-blocks__blocks-desktop"
      data-test="payout-blocks-blocks-desktop"
    >
      {data.map((d, index) =>
        d.numActiveSubs ? (
          <Link
            key={d.month}
            className={
              'payout-blocks__block-desktop ' +
              css`
                ${props.active === d.month.toLowerCase()
                  ? 'border: solid 1px #00ffb3;'
                  : 'border: solid 1px #000'};
                text-decoration: none;
              `
            }
            data-test="payout-blocks-block-desktop"
            to={`/month/${d.month.toLowerCase()}`}
            id={d.month}
          >
            <span
              className="payout-blocks__month-desktop"
              data-test="payout-blocks-block-month-desktop"
            >{`${d.month} ${d.year}`}</span>
            <span
              className="payout-blocks__amount-desktop"
              data-test="payout-blocks-block-amount-desktop"
            >
              {!d.amount ? 'Pending' : `$${numberWithCommas(d.amount)}`}
            </span>
            <span
              className="payout-blocks__users-desktop"
              data-test="payout-blocks-block-users-desktop"
            >{`${d.numActiveSubs} subscribers`}</span>
          </Link>
        ) : null
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  data: payoutBlocksData(state),
});

const mapDispatchToProps = {
  requestPayouts,
};

MonthPayoutBlockDesktop.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthPayoutBlockDesktop);
