import React from 'react';
import GraphNumberBlock from './GraphNumberBlock';
import PropTypes from 'prop-types';
import './graph-number.scss';

const GraphNumberSection = props => {
  return (
    <div className="graph-number__blocks" data-test="graph-number-blocks">
      <GraphNumberBlock
        number={props.leftNumber}
        text={props.leftText}
        identifier="left"
        data-test="graph-number-block"
      />
      <div className="graph-number__divider" />
      <GraphNumberBlock
        number={props.rightNumber}
        text={props.rightText}
        identifier="right"
        data-test="graph-number-block"
      />
      {props.payout ? <div className="graph-number__divider" /> : null}
      {props.payout ? (
        <GraphNumberBlock
          number={props.payout}
          text={props.payoutText}
          identifier="payout"
          data-test="graph-number-block"
        />
      ) : null}
    </div>
  );
};

GraphNumberBlock.propTypes = {
  leftNumber: PropTypes.number,
  rightNumber: PropTypes.number,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  payout: PropTypes.number,
  payoutText: PropTypes.string,
};

export default GraphNumberSection;
