import React from 'react';
import './BurgerButton.scss';

const BurgerButton = ({ open, ...props }) => (
  <button
    data-test="burger-button"
    aria-label="Menu"
    aria-controls="navigation"
    className={open ? 'burger-button open' : 'burger-button'}
    {...props}
  >
    <div
      className="burger-button__bar top"
      key="top"
      data-test="burger-button-bar"
    />
    <div
      className="burger-button__bar middle"
      key="middle"
      data-test="burger-button-bar"
    />
    <div
      className="burger-button__bar bottom"
      key="bottom"
      data-test="burger-button-bar"
    />
  </button>
);

export default BurgerButton;
