import actionTypes from './actionTypes';

export const loginUserRequest = () => ({
  type: actionTypes.LOGIN_REQUEST,
});

export const loginUserSuccess = user => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: user,
});

export const loginUserFailure = error => ({
  type: actionTypes.LOGIN_FAILURE,
  payload: error,
});

export const loadPayoutDataRequest = () => ({
  type: actionTypes.PAYOUT_DATA_REQUEST,
});

export const loadPayoutDataSuccess = data => ({
  type: actionTypes.PAYOUT_DATA_SUCCESS,
  payload: data,
});

export const loadPayoutDataFailure = error => ({
  type: actionTypes.PAYOUT_DATA_FAILURE,
  payload: error,
});

export const loadInfluencerDataRequest = () => ({
  type: actionTypes.INFLUENCER_DATA_REQUEST,
});

export const loadInfluencerDataSuccess = data => ({
  type: actionTypes.INFLUENCER_DATA_SUCCESS,
  payload: data,
});

export const loadInfluencerDataFailure = error => ({
  type: actionTypes.INFLUENCER_DATA_FAILURE,
  payload: error,
});

export const logoutSuccess = () => ({
  type: actionTypes.LOGOUT_SUCCESS,
});

export const logoutFailure = error => ({
  type: actionTypes.LOGOUT_FAILURE,
  payload: error,
});

export const loadContactsRequest = () => ({
  type: actionTypes.CONTACT_REQUEST,
});

export const loadContactsSuccess = contacts => ({
  type: actionTypes.CONTACT_SUCCESS,
  payload: contacts.sort((a, b) => a.priority - b.priority),
});

export const loadContactsFailure = error => ({
  type: actionTypes.CONTACT_FAILURE,
  payload: error,
});
