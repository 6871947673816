import React from 'react';
import format from 'date-fns/format';
import { ContentCard } from '../contentCard';
import { StatAmount, StatDifference } from '../statBlocks';

import { roundToDollars, getDiff } from '../../helpers/format';

export const RevenueReportBlock = props => {
  const [current, previous] = props.payoutData;
  const key = 'earned_amount_cents';

  return (
    <ContentCard
      active={props.active}
      navigationText="See Revenue Report"
      navigateTo="/revenue"
    >
      <div>
        <StatAmount>{current[key]}</StatAmount>
        {previous && (
          <StatDifference
            diff={getDiff(
              roundToDollars(current[key]),
              roundToDollars(previous[key])
            )}
            date={format(previous.day.split('T')[0], 'MMM DD')}
            insertChar="$"
          />
        )}
      </div>
      <div>Estimated Daily Revenue</div>
    </ContentCard>
  );
};
