import React, { Component } from 'react';
import './ContactPage.scss';
import { default as ListItemLink } from './ListItemLink.js';
import { default as ContactPersonPage } from './ContactPersonPage.js';
import { css } from 'emotion';
import MediaQuery from 'react-responsive';
import Page from '../page/Page.js';
import { connect } from 'react-redux';
import { requestContacts } from '../../actions/asyncActions';

const contactIdToServiceType = id => id.split('-').join('_');

const serviceTypeToUrl = serviceType => serviceType.split('_').join('-');

const serviceTypeToTitle = serviceType =>
  serviceType
    .split('_')
    .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join('-');

/**
 * Class React component for the ContentPage
 * @extends Component
 */
class ContactPage extends Component {
  componentDidMount() {
    if (!this.props.contacts) {
      this.props.requestContacts();
    }
  }

  render() {
    if (!this.props.contacts) return null;

    const { contacts, contactId } = this.props;

    const selectedContact =
      contactId &&
      contacts.find(c => {
        return c.serviceType === contactIdToServiceType(contactId);
      });

    return (
      <Page noNav>
        <div
          data-test="contact-page-container"
          className="contact-page-container"
        >
          <MediaQuery maxWidth={1199}>
            {!this.props.contactId ? (
              <div>
                <div className="contact-page-list-container">
                  <h1 className="contact-title" data-test="contact-title">
                    Got Questions?
                  </h1>
                  <p
                    className="contact-title-sub"
                    data-test="contact-title-sub"
                  >
                    Find the best person to talk to at Fitplan
                  </p>
                  <ul
                    className="contact-page-list"
                    data-test="contact-page-list"
                  >
                    {contacts.map((item, i) => {
                      return (
                        <ListItemLink
                          title={`${serviceTypeToTitle(
                            item.serviceType
                          )} Questions`}
                          key={i}
                          url={serviceTypeToUrl(item.serviceType)}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : (
              <ContactPersonPage {...selectedContact} />
            )}
          </MediaQuery>
          <MediaQuery minWidth={1200}>
            <div>
              <h1 className="contact-title" data-test="contact-title">
                Got Questions?
              </h1>
              <p className="contact-title-sub" data-test="contact-title-sub">
                Find the best person to talk to at Fitplan
              </p>
              <div
                className={css`
                  display: grid;
                  grid-template-columns: 50% 50%;
                `}
              >
                <div className="contact-page-inner-left">
                  <div className="contact-page-list-container">
                    <ul
                      className="contact-page-list-foobar"
                      data-test="contact-page-list"
                    >
                      {contacts.map((item, i) => {
                        return (
                          <ListItemLink
                            title={`${serviceTypeToTitle(
                              item.serviceType
                            )} Questions`}
                            key={i}
                            url={serviceTypeToUrl(item.serviceType)}
                            selected={
                              selectedContact &&
                              item &&
                              selectedContact._id &&
                              item._id &&
                              selectedContact._id === item._id
                            }
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="contact-page-inner-right">
                  {this.props.contactId ? (
                    <ContactPersonPage {...selectedContact} />
                  ) : null}
                </div>
              </div>
            </div>
          </MediaQuery>
        </div>
      </Page>
    );
  }
}

export default connect(
  state => ({ contacts: state.contacts }),
  { requestContacts }
)(ContactPage);
