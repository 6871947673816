import React from 'react';
import { connect } from 'react-redux';
import { requestPayouts } from '../../actions/asyncActions';
import { payoutBlocksData } from '../../selectors';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import './payout-blocks.scss';

const linkStyle = {
  textDecoration: 'none',
  cursor: 'pointer',
};

const numberWithCommas = x => {
  if (!x) return null;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const MonthPayoutBlock = props => {
  const { data } = props;

  return (
    <div className="payout-blocks__blocks" data-test="payout-blocks-blocks">
      {data.map((d, index) =>
        d.numActiveSubs ? (
          <Link
            key={d.month}
            style={linkStyle}
            to={`/month/${d.month.toLowerCase()}`}
          >
            <div
              className="payout-blocks__block"
              data-test="payout-blocks-block"
            >
              <span
                className="payout-blocks__month"
                data-test="payout-blocks-block-month"
              >{`${d.month} ${d.year}`}</span>
              <span
                className="payout-blocks__amount"
                data-test="payout-blocks-block-amount"
              >
                {!d.amount ? 'Pending' : `$${numberWithCommas(d.amount)}`}
              </span>
              <span
                className="payout-blocks__users"
                data-test="payout-blocks-block-users"
              >{`${d.numActiveSubs} subscribers`}</span>
            </div>
          </Link>
        ) : null
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  data: payoutBlocksData(state),
});

const mapDispatchToProps = {
  requestPayouts,
};

MonthPayoutBlock.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthPayoutBlock);
