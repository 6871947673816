import React, { Component } from 'react';
import './ListItemLink.scss';

import { Icon } from 'react-icons-kit';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';

import { navigate } from '@reach/router';

/**
 * Class React component for the ContentPage
 * @extends Component
 */

class ListItemLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let className = 'contact-list-item';
    if (this.props.selected) {
      className = `${className} selected`;
    }

    return (
      <li
        className={className}
        data-test="contact-list-item"
        onClick={() => navigate(`/contact/${this.props.url}`)}
      >
        <p className="contact-list-item-title">{this.props.title}</p>
        <Icon className="contact-list-item-icon" icon={chevronDown} />
      </li>
    );
  }
}
export default ListItemLink;
