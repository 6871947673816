import chroma from 'chroma-js';
export const COLORS = {
  brightBlue: '#0076f9',
  brightGreen: '#32feb5',
  darkRed: '#f10364',
  yellow: '#f5b700',
};

export const GRAPHCOLORS = {
  num_paid: COLORS.brightBlue,
  num_left: COLORS.darkRed,
  num_joined: COLORS.brightGreen,
  num_new_trials: COLORS.brightBlue,
  num_paid_high: COLORS.brightBlue,
  num_paid_low: COLORS.darkRed,
  num_days: COLORS.brightBlue,
  num_starts: COLORS.brightGreen,
  num_ends: COLORS.darkRed,
  num_trials: COLORS.yellow,
  num_users_monthly: chroma(COLORS.brightBlue)
    .darken()
    .hex(),
  num_users_annual: chroma(COLORS.brightBlue)
    .brighten()
    .hex(),
  num_users_quarter: COLORS.brightBlue,
};

export const GRAPHNAMES = {
  num_paid: 'Subscribers',
  num_new_trials: 'New Trials',
  num_left: 'Lost Subscribers',
  num_joined: 'New Subscribers',
  num_paid_high: 'High Estimate',
  num_paid_low: 'Low Estimate',
  num_days: 'Daily Paid Users',
  num_starts: 'New Daily Paid Users',
  num_ends: 'Lost Daily Paid Users',
  num_trials: 'Users on Trial',
  num_users_annual: 'Annual Users',
  num_users_quarter: 'Quarterly Users',
  num_users_monthly: 'Monthly Users',
};
