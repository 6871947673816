import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ComingSoon from '../comingSoon/ComingSoon';
import Blur from '../blur/Blur';

import {
  activeUsersSelector,
  totalSubsSelector,
  totalLeftSelector,
  expectedPayoutSelector,
  nextPayoutDateSelector,
} from '../../selectors';
import './UserPayoutStats.scss';

const UserPayoutStats = props => {
  const [leftColor, rightColor] = props.blockColorClasses;

  return (
    <div className="user-payout__container" data-test="user-payout__container">
      <div className="user-payout__container-grid">
        <div
          className={`user-payout__container-grid-left ${leftColor}`}
          data-test="user-payout__container-grid-left"
        >
          <p data-test="active-users">{props.activeUsers}</p>
          <p>Subscribers</p>
        </div>
        <Blur
          blur={!props.expectedPayout}
          overlay={<ComingSoon feature="Expected Payouts" />}
        >
          <div
            className={`user-payout__container-grid-right ${rightColor}`}
            data-test="user-payout__container-grid-right"
          >
            <p data-test="expected-payout">
              {props.expectedPayout ? `$${props.expectedPayout}` : '$6000'}
            </p>
            <p>Expected Payout*</p>
          </div>
        </Blur>
      </div>
      <span className="user-payout__asterisk" data-test="user-payout-asterisk">
        *based on current numbers
      </span>
    </div>
  );
};

UserPayoutStats.defaultProps = {
  activeUsers: 254,
  subscribers: 1207,
  expectedPayout: 2,
  blockColorClasses: ['number-green', 'number-blue', 'number-white'],
  nextPayout: '2018-10-02T00:00:00.000Z',
};

UserPayoutStats.propTypes = {
  activeUsers: PropTypes.number,
  subscribers: PropTypes.number,
  expectedPayout: PropTypes.number,
  nextPayout: PropTypes.string,
  blockColorClasses: PropTypes.arrayOf(PropTypes.string),
};

export default connect(state => ({
  activeUsers: activeUsersSelector(state.data),
  subscribers: totalSubsSelector(state.data),
  expectedPayout: expectedPayoutSelector(state.data),
  nextPayout: nextPayoutDateSelector(state.data),
  numLeft: totalLeftSelector(state.data),
}))(UserPayoutStats);
