import React from 'react';
import PropTypes from 'prop-types';
import './graph-number.scss';

const numberWithCommas = x => {
  if (!x && x !== 0) return null;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const GraphNumberBlock = props => (
  <div className="graph-number__block">
    <span className={`graph-number__number ${props.identifier}`}>
      {props.identifier === 'payout'
        ? `$${numberWithCommas(props.number)}`
        : numberWithCommas(props.number)}
    </span>
    <span className="graph-number__text">{props.text}</span>
  </div>
);

GraphNumberBlock.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default GraphNumberBlock;
