import React from 'react';
import { LoginPageForm, Spinner } from '../';
import { css } from 'emotion';
import './LoginPage.scss';

const email = 'ashley@fitplanapp.com';

const LoginPage = props => (
  <div className="login__container" data-test="login-container">
    <Spinner active={props.isFetching} />
    <h1 className="login__container-title" data-test="login-title">
      Welcome to your dashboard
    </h1>
    <h3 className="login__container-subtitle" data-test="login-subtitle">
      Get access to your plans stats, earnings and more, to gain insights on
      your users and track your growth with Fitplan.
    </h3>
    <p className="login__error">{props.errorMessage}</p>
    <LoginPageForm onSubmit={props.onSubmit} />
    <div className="login__container-contact" data-test="login-contact">
      <span className="login__contact-question">Forgot password?</span>
      <a
        className={css`
          text-decoration: none;
        `}
        href={`mailto:${email}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="login__contact-link">Contact Ashley</span>
      </a>
    </div>
  </div>
);

export default LoginPage;
