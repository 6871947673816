import React from 'react';
import { Page } from '../';
import { SubscribersGraph } from '../';
import format from 'date-fns/format';

const SubscribersPage = () => (
  <Page>
    <SubscribersGraph />
  </Page>
);

SubscribersPage.defaultProps = {
  month: format(new Date(), 'MMMM'), // current month
};

export default SubscribersPage;
