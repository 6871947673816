const env = process.env.NODE_ENV || 'development';

require('dotenv').config({
  path: `.env.${env}`,
});

const apiBaseUrl = environment =>
  ({
    development: 'https://ccb5cd9c49.execute-api.us-east-1.amazonaws.com/dev',
    // development: 'https://74il9tkydg.execute-api.us-east-1.amazonaws.com/prod',
    production: 'https://74il9tkydg.execute-api.us-east-1.amazonaws.com/prod',
  }[environment]);

module.exports = {
  api: {
    base: apiBaseUrl(env),
    endpoints: {
      login: '/login',
      payoutData: '/payout-data',
      influenceData: '/influencer-data',
      customerContacts: '/customer-contacts',
    },
    dashboardApiKey: process.env.REACT_APP_DASHBOARD_CLIENT_KEY,
  },
};
