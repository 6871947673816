import React from 'react';
import { Link } from '@reach/router';
import './link-contact.scss';

const LinkToContact = props => (
  <div className="link-contact__container">
    <span className="link-contact__question">{props.question}</span>
    <br />
    <Link to={`/contact/${props.subject}`} style={{ textDecoration: 'none' }}>
      <span className="link-contact__link">{`Ask ${props.firstName}`}</span>
    </Link>
  </div>
);

export default LinkToContact;
