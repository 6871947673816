import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { angleLeft } from 'react-icons-kit/fa/angleLeft';
import { angleRight } from 'react-icons-kit/fa/angleRight';
import './RangeSlider.css';

class RangeSlider extends Component {
  static defaultProps = {
    height: 88,
    min: 1,
    max: 100,
    onChange: () => null,
    points: 30,
    width: 250,
    value: 100,
    bottom: 46,
  };

  static propTypes = {
    height: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    points: PropTypes.number,
    width: PropTypes.number,
    value: PropTypes.number,
    bottom: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      maxWidth: this.props.width - 3,
      position: this.props.width - 3,
      dragging: false,
    };
    this.lastCalcPoint = this.props.points - 1;
    this.slideRuleRef = React.createRef();
  }

  handleMouseStart = e => {
    e.preventDefault();
    const { pageX } = e;
    this.setInitialPositionValues(pageX);
  };

  handleTouchEnd = () => {
    this.setState({ dragging: false });
  };

  handleTouchStart = e => {
    const pageX = e.changedTouches[0].pageX;
    this.setInitialPositionValues(pageX);
  };

  handleMouseEnd = e => {
    e.preventDefault();
    this.setState({
      dragging: false,
    });
  };

  handleMouseMove = e => {
    e.preventDefault();
    if (!this.state.dragging) return;
    const { pageX } = e;
    this.updatePosition(pageX);
  };

  handleTouchMove = e => {
    if (!this.state.dragging) return;
    const pageX = e.changedTouches[0].pageX;
    this.updatePosition(pageX);
  };

  handleKeyDown = e => {
    const { position, maxWidth } = this.state;
    const updateByX =
      {
        ArrowLeft: -3,
        ArrowRight: 3,
      }[e.key] || 0;

    let newPosition = position + updateByX;
    if (newPosition < 0) newPosition = 0;
    if (newPosition > maxWidth) newPosition = maxWidth;

    this.setState({
      position: newPosition,
    });
  };

  setInitialPositionValues(pageX) {
    this.setState({
      dragging: true,
      offset: this.slideRuleRef.current.offsetLeft,
      posX: pageX,
      relX: pageX,
    });
  }

  updatePosition(pageX) {
    const { maxWidth, relX, offset } = this.state;
    const calculatedPostion = pageX - relX + offset;
    let newPosition = calculatedPostion;
    if (calculatedPostion < 0) newPosition = 0;
    if (calculatedPostion > maxWidth) newPosition = maxWidth;

    this.setState({
      posX: pageX,
      position: newPosition,
    });
  }

  calculateOutputValue(newPosition) {
    let outPut;
    const { points } = this.props;
    const { maxWidth } = this.state;
    const divisor = maxWidth / points;

    if (newPosition === 0) {
      outPut = 0;
    } else if (newPosition === maxWidth) {
      outPut = points - 1; // array index length - 1
    } else {
      outPut = Math.floor(newPosition / divisor);
    }

    if (this.lastCalcPoint !== outPut) {
      this.lastCalcPoint = outPut;
      this.props.onChange(outPut);
    }
    return this.lastCalcPoint;
  }

  componentDidUpdate(prevProps, prevState) {
    const { position } = this.state;
    if (this.props.width !== prevProps.width) {
      this.setState({
        maxWidth: this.props.width - 3,
        position: this.props.width - 3,
        dragging: false,
      });
    }
    this.calculateOutputValue(position);
  }

  render() {
    const position = this.state.position;
    const min = this.props.min;
    const max = this.props.max;
    const orientation = 'horizontal';
    const value = max / (this.lastCalcPoint || 0.1);

    return (
      <div
        style={{
          width: `${this.props.width}px`,
          height: `${this.props.height}px`,
          bottom: `${this.props.bottom}px`,
        }}
        data-test="component-range-slider"
        className="range-slider"
        onMouseLeave={this.handleMouseEnd}
        onMouseMove={this.handleMouseMove}
        onTouchMove={this.handleTouchMove}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        aria-orientation={orientation}
      >
        <div data-test="slider-base-bar" className="range-slider__base-bar" />
        <div
          data-test="slider-rule"
          ref={this.slideRuleRef}
          className="range-slider__rule"
          style={{ left: `${position}px` }}
        >
          <div
            data-test="slider-button"
            role="button"
            className="button__control"
            onMouseDown={this.handleMouseStart}
            onMouseUp={this.handleMouseEnd}
            onTouchStart={this.handleTouchStart}
            onTouchEnd={this.handleTouchEnd}
            onKeyDown={this.handleKeyDown}
            tabIndex={0}
          >
            <div
              data-test="slider-button-icon"
              className="button__control-left"
            >
              <Icon icon={angleLeft} />
            </div>
            <div
              data-test="slider-button-icon"
              className="button__control-right"
            >
              <Icon icon={angleRight} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RangeSlider;
