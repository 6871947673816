import React from 'react';
import { css } from 'emotion';
import { MonthPayoutBlock, MonthPayoutBlockDesktop } from '../';
import PropTypes from 'prop-types';
import './payout-blocks.scss';

const GridPayoutBlocks = props => {
  return (
    <div>
      <div
        className={css`
          @media (min-width: 1199px) {
            display: none;
          }
        `}
      >
        <div
          className="payout-blocks__container"
          data-test="payout-blocks-container"
        >
          <MonthPayoutBlock />
        </div>
      </div>
      <div
        className={css`
          @media (max-width: 1200px) {
            display: none;
          }
        `}
      >
        <div
          className="payout-blocks__container-desktop"
          data-test="payout-blocks-container-desktop"
        >
          <MonthPayoutBlockDesktop active={props.activeMonth} />
        </div>
      </div>
    </div>
  );
};

GridPayoutBlocks.propTypes = {
  activeMonth: PropTypes.string,
};
GridPayoutBlocks.defaultProps = {
  activeMonth: null,
};

export default GridPayoutBlocks;
