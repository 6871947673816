import React from 'react';
import { Page } from '../';
import History from './History';
import './history-page.scss';

const HistoryPage = props => (
  <Page month={props.month}>
    <History key={props.month} month={props.month} />
  </Page>
);

export default HistoryPage;
