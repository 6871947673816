import React from 'react';
import './user-payout.scss';
import { css } from 'emotion';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { StatsBlockSection } from '../';
import ComingSoon from '../comingSoon/ComingSoon';
import Blur from '../blur/Blur';
import PropTypes from 'prop-types';

import {
  activeUsersSelector,
  activeUsersDifferenceSelector,
  expectedPayoutSelector,
  expectedPayoutDifferenceSelector,
} from '../../selectors';

const UserPayoutBlock = props => {
  const { activeUsers, expectedPayout } = props;
  return (
    <Link to="/subscribers" style={{ textDecoration: 'none' }}>
      <div className="user-payout__container">
        <div
          className={
            'user-payout__block ' +
            css`
              ${props.active
                ? 'border: solid 1px #00ffb3;'
                : 'border: solid 1px #000'};
            `
          }
          tabIndex="0"
          role="button"
        >
          <StatsBlockSection
            number={activeUsers.totalUsers}
            text="total subscribers"
            difference={activeUsers.difference}
            data-test="user-payout-block"
          />
          <div className="user-payout__divider" />
          <Blur
            blur={!expectedPayout.payout}
            overlay={<ComingSoon feature="Expected Payouts" />}
          >
            <StatsBlockSection
              number={
                expectedPayout.payout ? `$${expectedPayout.payout}` : '$6000'
              }
              text="expected payout*"
              difference={expectedPayout.difference}
              data-test="user-payout-block"
            />
          </Blur>
        </div>
      </div>
    </Link>
  );
};

UserPayoutBlock.propTypes = {
  activeUsers: PropTypes.shape({
    totalUsers: PropTypes.number,
    difference: PropTypes.number,
  }),
  expectedPayout: PropTypes.shape({
    payout: PropTypes.string,
    difference: PropTypes.number,
  }),
};

UserPayoutBlock.defaultProps = {
  activeUsers: {
    totalUsers: 973,
    difference: 50,
  },
  expectedPayout: {
    payout: '$10,145',
    difference: 325,
  },
};

const mapStateToProps = state => ({
  activeUsers: {
    totalUsers: activeUsersSelector(state.data),
    difference: activeUsersDifferenceSelector(state.data),
  },
  expectedPayout: {
    payout: expectedPayoutSelector(state.data),
    difference: expectedPayoutDifferenceSelector(state.data),
  },
});

export default connect(
  mapStateToProps,
  null
)(UserPayoutBlock);
