import axios from 'axios';
import format from 'date-fns/format';
import subMonths from 'date-fns/sub_months';
import startOfMonth from 'date-fns/start_of_month';

import {
  loginUserSuccess,
  loginUserRequest,
  loginUserFailure,
  loadPayoutDataFailure,
  loadPayoutDataSuccess,
  loadPayoutDataRequest,
  loadInfluencerDataFailure,
  loadInfluencerDataSuccess,
  loadInfluencerDataRequest,
  loadContactsFailure,
  loadContactsSuccess,
  loadContactsRequest,
} from './';
import { api } from '../config';

export const loginUser = userInfo => {
  return dispatch => {
    dispatch(loginUserRequest());
    return axios({
      method: 'POST',
      headers: {
        'x-api-key': api.dashboardApiKey,
      },
      url: `${api.base}${api.endpoints.login}`,
      data: userInfo,
    })
      .then(res => {
        const { data } = res;
        if (data.error) {
          dispatch(loginUserFailure({ message: 'Wrong credentials' }));
        } else {
          // call back into request payouts so that we ensure the data has arrived before
          // attempting to render
          dispatch(
            requestPayouts({
              user: data,
              successCBs: [
                () => dispatch(requestInfluencerData({ user: data })),
                () => dispatch(requestContacts()),
              ],
              failureCBs: [
                () =>
                  dispatch(loginUserFailure({ message: 'Wrong credentials' })),
              ],
            })
          );
        }
      })
      .catch(err => {
        dispatch(loginUserFailure({ message: 'Wrong credentials' }));
      });
  };
};

export const requestInfluencerData = options => {
  const defaultOptions = {
    periods: [...Array(6).keys()]
      .map(key =>
        format(startOfMonth(subMonths(new Date(), key)), 'YYYY-MM-DD')
      )
      .join(','),
  };
  const { user, periods } = Object.assign({}, defaultOptions, options);

  return (dispatch, getState) => {
    dispatch(loadInfluencerDataRequest());
    return axios({
      method: 'get',
      headers: {
        'x-api-key': api.dashboardApiKey,
        Authorization: `Bearer ${user.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      url: `${api.base}${api.endpoints.influenceData}`,
      params: {
        periods,
      },
    })
      .then(res => {
        if (res.data) {
          dispatch(loadInfluencerDataSuccess(res.data));
          dispatch(loginUserSuccess(options.user));
        }
      })
      .catch(err => {
        console.log({ err });
        dispatch(loadInfluencerDataFailure({ message: 'request denied' }));
      });
  };
};

export const requestPayouts = options => {
  const defaultOptions = {
    current: 1, // initial request includes current month data
    historyEnd: format(startOfMonth(new Date()), 'YYYY-MM-DD'), //begining of this month
    historyStart: format(subMonths(new Date(), 6), 'YYYY-MM-DD'), // 6 months ago
  };

  const {
    historyStart,
    historyEnd,
    current,
    successCBs,
    failureCBs,
    user,
  } = Object.assign({}, defaultOptions, options);

  return (dispatch, getState) => {
    dispatch(loadPayoutDataRequest());
    const { user: loggedInUser } = getState();
    if (!user || !loggedInUser) return null;

    return axios({
      method: 'get',
      headers: {
        'x-api-key': api.dashboardApiKey,
        Authorization: `Bearer ${user.accessToken || loggedInUser.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      url: `${api.base}${api.endpoints.payoutData}`,
      params: {
        historyStart,
        historyEnd,
        current,
      },
    })
      .then(res => {
        if (res.data) {
          if (successCBs) {
            successCBs.forEach(cb => {
              cb();
            });
          }
          dispatch(loadPayoutDataSuccess(res.data));
        }
      })
      .catch(err => {
        console.log({ err });
        if (failureCBs) {
          failureCBs.forEach(cb => {
            cb();
          });
        }
        dispatch(loadPayoutDataFailure({ message: 'request denied' }));
      });
  };
};

export const requestContacts = () => {
  return (dispatch, getState) => {
    dispatch(loadContactsRequest());
    return axios({
      method: 'get',
      headers: {
        'x-api-key': api.dashboardApiKey,
      },
      url: `${api.base}${api.endpoints.customerContacts}`,
    })
      .then(res => {
        dispatch(loadContactsSuccess(res.data.contacts));
      })
      .catch(err => {
        console.log(err);
        dispatch(loadContactsFailure({ message: 'oops something went wrong' }));
      });
  };
};
