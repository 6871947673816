const actionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  PAYOUT_DATA_REQUEST: 'PAYOUT_DATA_REQUEST',
  PAYOUT_DATA_SUCCESS: 'PAYOUT_DATA_SUCCESS',
  PAYOUT_DATA_FAILURE: 'PAYOUT_DATA_FAILURE',
  INFLUENCER_DATA_REQUEST: 'INFLUENCER_DATA_REQUEST',
  INFLUENCER_DATA_SUCCESS: 'INFLUENCER_DATA_SUCCESS',
  INFLUENCER_DATA_FAILURE: 'INFLUENCER_DATA_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  CONTACT_REQUEST: 'CONTACT_REQUEST',
  CONTACT_SUCCESS: 'CONTACT_SUCCESS',
  CONTACT_FAILURE: 'CONTACT_FAILURE',
};

export default actionTypes;
