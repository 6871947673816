import React from 'react';
import { Page } from '../';
import format from 'date-fns/format';

const StatDetailPage = React.lazy(() => import('../statDetailPage'));

const CurrentPage = props => (
  <Page>
    <StatDetailPage {...props} />
  </Page>
);

CurrentPage.defaultProps = {
  month: format(new Date(), 'MMMM'), // current month
};

export default CurrentPage;
