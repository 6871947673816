import React from 'react';
import './BackArrow.scss';
import { Icon } from 'react-icons-kit';
import { chevronLeft } from 'react-icons-kit/fa/chevronLeft';
import { Location } from '@reach/router';

const BackArrow = ({ back }) => {
  return (
    <Location>
      {({ location }) =>
        location.pathname !== '/' ? (
          <button className="back-arrow" onClick={back} data-test="back-arrow">
            <Icon size={24} icon={chevronLeft} />
          </button>
        ) : null
      }
    </Location>
  );
};

export default BackArrow;
