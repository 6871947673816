import addDays from 'date-fns/add_days';
import isPast from 'date-fns/is_past';
// not writting tests at the moment for these helpers as the documented way
// of mocking localStorage and sessionStorage were not working.

const hasData = obj => {
  const length = Object.values(obj).length;

  return length > 0;
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('__athleteDashboard__');
    const expires = localStorage.getItem('__athleteDashboard__expires');
    if (serializedState === null || isPast(new Date(expires))) {
      return undefined;
    }

    const parsedData = JSON.parse(serializedState);

    // If there is not the correct types of data clear the storage
    if (!hasData(parsedData.influencerData) || !hasData(parsedData.data)) {
      localStorage.clear();
      return undefined;
    }

    return parsedData;
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('__athleteDashboard__', serializedState);
    localStorage.setItem('__athleteDashboard__expires', addDays(new Date(), 1));
  } catch (err) {
    // Ignore write errors.
  }
};

export const removeState = () => {
  if (localStorage.getItem('__athleteDashboard__' === null)) {
    return false;
  }
  localStorage.removeItem('__athleteDashboard__');
  return true;
};
