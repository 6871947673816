import React from 'react';
import { css } from 'emotion';
import format from 'date-fns/format';
import { COLORS } from '../../constants';

import { formatNumber, roundToDollars } from '../../helpers/format';

export const StatList = ({ children, date }) => (
  <div
    className={css`
      font-family: Barlow;
      font-size: 12px;
      flex: 1;
    `}
  >
    <span
      className={css`
        text-transform: uppercase;
      `}
    >
      {format(date, 'MMM DD')}
    </span>
    <ul
      className={css`
        list-style: none;
        padding: 0;
        margin: 8px 0;
      `}
    >
      {children}
    </ul>
  </div>
);

export const StatSection = ({ children }) => (
  <section
    className={css`
      display: flex;
      margin-top: 24px;
      padding: 0 16px;
    `}
  >
    {children}
  </section>
);

export const StatListItem = ({ children }) => <li>{children}</li>;

export const StatAmount = ({ children, fallback }) => (
  <span
    className={css`
      font-family: 'Barlow Condensed';
      font-size: 32px;
      font-weight: bold;
      line-height: 1.25;
      margin-bottom: 8px;
      margin-right: 0.385em;
    `}
  >
    {children ? `$${formatNumber(roundToDollars(children))}` : fallback}
  </span>
);

export const StatAmountBlock = ({ children }) => (
  <div
    className={css`
      border-left: 1px solid #5a5c61;
      padding: 0 12px;
      font-family: Barlow;
      font-size: 12px;
      line-height: 1.33;
      display: flex;
      flex-direction: column;
      margin: 20px 15px 15px;
    `}
  >
    {children}
  </div>
);
export const StatTitle = ({ children }) => (
  <span
    className={css`
      max-width: 90px;
      text-transform: uppercase;
    `}
  >
    {children}
  </span>
);

export const StatNumber = ({ children, color, size }) => (
  <span
    className={css`
      color: ${color};
      font-family: 'Barlow Condensed';
      font-size: ${size}px;
      font-weight: bold;
      margin-right: 0.385em;
    `}
  >
    {formatNumber(children)}
  </span>
);

StatNumber.defaultProps = {
  color: '#fff',
  size: 21,
};

export const StatDifference = ({ diff, date, insertChar }) => {
  const sign = diff >= 0 ? '+' : '-';

  return (
    <span
      className={css`
        color: ${diff >= 0 ? COLORS.brightGreen : COLORS.darkRed};
      `}
    >{`${sign}${insertChar ? insertChar : ''}${Math.abs(
      diff
    )} vs ${date}`}</span>
  );
};
