import React, { Component } from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { merge } from '../../helpers/objectUtil';
import { arrayObjKeyEquality } from '../../helpers/arrayUtils';
// import './Graph.css';
import './graph.scss';

const defaultMargins = { top: 20, right: 40, bottom: 25, left: 40 };

// TODO: refactor this to remove d3 rendering from the code. d3 can output path data that react can use directly.
/**
 * Class React component Graph
 * @name Graph
 * @extends Component
 * @example
 *  <Graph
 *    path="/graph"
 *    fill
 *    yAxisMarksLeft
 *    yAxisMarksRight
 *    xAxisMarkEnd
 *    xAxisMarkStart
 *    dataSets={[data, data2]}
 *  />
 *  @returns {JSX.Element}
 */
class Graph extends Component {
  constructor(props) {
    super(props);

    this.svgRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.dataSets) {
      this.drawChart();
    }
    this.reportDims();
  }

  reportDims(prevState) {
    const { height, width } = this.wrapperRef.current.getBoundingClientRect();
    if (
      !prevState ||
      (prevState.width !== width || prevState.height !== height)
    ) {
      this.setState({ height, width });

      if (this.props.reportDims) {
        this.props.reportDims({
          width: width * 0.75,
          height: height * 0.8,
          bottom: height * 0.12,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const [prevDataset] = prevProps.dataSets;
    const [dataset] = this.props.dataSets;

    if (
      !prevDataset ||
      !dataset ||
      prevProps.dataSets.length !== this.props.dataSets.length ||
      !arrayObjKeyEquality(prevDataset.data, dataset.data, 'date')
    ) {
      this.removeChart();
      this.drawChart();
      this.reportDims(prevState);
    }
  }

  getMinMaxArr() {
    const [dataset] = this.props.dataSets;
    // get min and max x value for the axis
    const xMin = d3.min(dataset.data, v => v.date);
    const xMax = d3.max(dataset.data, v => v.date);

    return [xMin, xMax];
  }

  getTickValues() {
    const [min, max] = this.getMinMaxArr();
    const tickValues = [];

    if (this.props.xAxisMarkStart) {
      tickValues.push(min);
    }

    if (this.props.xAxisMarkEnd) {
      tickValues.push(max);
    }

    return tickValues;
  }

  removeChart() {
    const svg = d3.select(this.svgRef.current);
    const g = svg.select('g');
    g.remove();
  }

  getMaxDataSetValue() {
    if (this.props.max) return this.props.max;

    const maxValue = this.props.dataSets.reduce((acc, dataset) => {
      const max = d3.max(dataset.data, d => d.value);
      return max > acc ? max : acc;
    }, 0);

    return maxValue;
  }

  getSortedDataSetsByMaxValue() {
    const datasets = this.props.dataSets.slice(); // copy since sort mutates

    return datasets.sort((a, b) => {
      const maxA = d3.max(a.data, d => d.value);
      const maxB = d3.max(b.data, d => d.value);

      if (maxA < maxB) return 1;
      if (maxA > maxB) return -1;

      return 0;
    });
  }

  drawChart() {
    if (!this.props.dataSets[0] || !this.props.dataSets[0].data.length) return;
    const { svgWidth, svgHeight, maxHeight } = this.props;
    const margin = merge(defaultMargins, this.props.margins);
    const width = svgWidth - margin.left - margin.right;
    const height = (maxHeight || svgHeight) - margin.top - margin.bottom;
    const svg = d3
      .select(this.svgRef.current)
      .attr('viewBox', `0 0 ${svgWidth} ${maxHeight || svgHeight}`)
      .attr('preserveAspectRatio', 'xMinYMin meet');

    const g = svg
      .append('g')
      .attr('data-test', 'graph-content')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    //set scale on chart
    const x = d3.scaleTime().rangeRound([0, width]);
    const y = d3.scaleLinear().rangeRound([height, 0]);
    x.domain(this.getMinMaxArr());
    y.domain([0, this.getMaxDataSetValue()]);

    const sortedDatasets = this.getSortedDataSetsByMaxValue();

    const xAxis = d3
      .axisBottom(x)
      .tickFormat(d => format(d, 'MMM D'))
      .tickValues(this.getTickValues());

    if (this.props.xAxisMarkStart || this.props.xAxisMarkEnd) {
      //add x axis marks
      g.append('g')
        .call(xAxis)
        .attr('data-test', 'x-axis-marks')
        .attr('transform', 'translate(0,' + height + ')')
        .attr('class', 'axis')
        .attr('fill', this.props.xAxisColor);
    }
    // calculate the axes
    const yAxisLeft = d3
      .axisLeft(y)
      .tickValues(sortedDatasets.map(({ data }) => data[0].value));

    const yAxisRight = d3
      .axisRight(y)
      .tickValues(
        sortedDatasets.map(({ data }) => data[data.length - 1].value)
      );

    if (this.props.yAxisMarksLeft) {
      // add y axis values left
      g.append('g')
        .call(yAxisLeft)
        .attr('name', `y-axis-left`)
        .attr('data-test', 'y-axis-marks-left')
        .attr('class', 'axis')
        .selectAll('text')
        .attr('fill', (d, i) => sortedDatasets[i].color);
    }

    if (this.props.yAxisMarksRight) {
      // add y axis values right
      g.append('g')
        .call(yAxisRight)
        .attr('name', `y-axis-right`)
        .attr('data-test', 'y-axis-marks-right')
        .attr('transform', `translate(${width}, 0 )`)
        .attr('class', 'axis')
        .selectAll('text')
        .attr('fill', (d, i) => sortedDatasets[i].color);
    }

    sortedDatasets.forEach(({ name, color, data, key }, i) => {
      // define the area
      const area = d3
        .area()
        .x(d => x(d.date))
        .y0(height)
        .y1(d => y(d.value));

      // define the line
      const valueline = d3
        .line()
        .x(d => x(d.date))
        .y(d => y(d.value));

      //create line and area
      if (data.length) {
        const lineStyle =
          this.props.lineStyle === 'dash'
            ? ['stroke-dasharray', '3,3']
            : ['stroke', color];
        const fillGradientName = `${
          this.props.name ? `${this.props.name}-` : ''
        }${color}`;

        g.append('path')
          .data([data])
          .style(...lineStyle)
          .attr('class', 'line')
          .attr('data-test', 'graph-line')
          .attr('stroke', color)
          .attr('stroke-width', 2)
          .attr('fill', 'none')
          .attr('name', name)
          .attr('d', valueline);

        if (this.props.fill) {
          const areaGradient = g
            .append('defs')
            .append('linearGradient')
            .attr('id', `areaGradient-${fillGradientName}`)
            .attr('x1', '0%')
            .attr('y1', '0%')
            .attr('x2', '0%')
            .attr('y2', '100%');

          areaGradient
            .append('stop')
            .attr('offset', '0%')
            .attr('stop-color', color)
            .attr('stop-opacity', 0.5);

          areaGradient
            .append('stop')
            .attr('offset', '100%')
            .attr('stop-color', color)
            .attr('stop-opacity', 0);

          g.append('path')
            .data([data])
            .attr('data-test', 'graph-fill')
            .style('fill', `url(#areaGradient-${fillGradientName})`)
            .attr('d', area);
        }
      }
    });
  }

  render() {
    return (
      <div
        ref={this.wrapperRef}
        data-test="component-line-chart"
        style={{ width: `100%` }}
      >
        <svg
          data-test="graph-container"
          className="graph__container"
          ref={this.svgRef}
        />
      </div>
    );
  }
}

Graph.propTypes = {
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.instanceOf(Date).isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  margins: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number,
  }),
  svgHeight: PropTypes.number,
  svgWidth: PropTypes.number,
  xAxisMarkStart: PropTypes.bool,
  xAxisMarkEnd: PropTypes.bool,
  yAxisMarksRight: PropTypes.bool,
  yAxisMarksLeft: PropTypes.bool,
  fill: PropTypes.bool,
  xAxisColor: PropTypes.string,
  maxHeight: PropTypes.number,
};

Graph.defaultProps = {
  fill: false,
  xAxisMarkStart: false,
  xAxisMarkEnd: false,
  yAxisMarksRight: false,
  yAxisMarksLeft: false,
  xAxisColor: '#FFFFFF',
  svgHeight: 200,
  svgWidth: 300,
  margins: defaultMargins,
};

export default Graph;
