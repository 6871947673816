import React from 'react';
import PropTypes from 'prop-types';
import '../graphWithSlider/GraphWithSlider.css';
import { pick, merge } from '../../helpers/objectUtil.js';
import { Graph, RangeSlider } from '../';
import { css } from 'emotion';

const graphKeys = [
  'graphColors',
  'xAxisMarkStart',
  'xAxisMarkEnd',
  'xAxisColor',
  'yAxisMarksLeft',
  'yAxisMarksRight',
];
const sliderKeys = ['onChange', 'value', 'min', 'max'];

const calculateMax = array => {
  return Math.max(...array.map(item => item.value));
};

class ProjectionGraphWithSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const { height, width } = this.wrapperRef.current.getBoundingClientRect();
    this.setDims({
      width: width * 0.75,
      height: height * 0.75,
      bottom: height * 0.15,
    });
  }

  setDims(options) {
    const { width, height, bottom } = options;
    this.setState({ width, height, bottom });
  }

  render() {
    const graphProps = pick(this.props, graphKeys);
    const sliderProps = merge(pick(this.props, sliderKeys), {
      points: this.props.dataSetsActual[0].data.concat(
        this.props.dataSetsProjection[0].data
      ).length,
    });

    const max = calculateMax([
      ...this.props.dataSetsActual[0].data,
      ...this.props.dataSetsProjection[0].data,
    ]);

    return (
      <div data-test="component-projected-graph" className="graph-with-slider">
        <div
          className={css`
            display: flex;
            width: 100%;
          `}
          ref={this.wrapperRef}
        >
          <Graph
            {...graphProps}
            fill
            dataSets={this.props.dataSetsActual}
            svgWidth={this.props.svgWidth / 2}
            margins={{ right: 0, left: 40 }}
            xAxisMarkEnd={false}
            max={max}
          />
          <Graph
            {...graphProps}
            dataSets={this.props.dataSetsProjection}
            svgWidth={this.props.svgWidth / 2}
            lineStyle="dash"
            margins={{ left: 0, right: 40 }}
            xAxisMarkStart={false}
            yAxisMarksRight={true}
            max={max}
          />
        </div>
        <RangeSlider
          {...sliderProps}
          width={this.state.width}
          height={this.state.height}
          bottom={this.state.bottom}
        />
      </div>
    );
  }
}

ProjectionGraphWithSlider.propTypes = {
  dataSets: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.instanceOf(Date).isRequired,
        value: PropTypes.number,
      })
    )
  ),
  graphColors: PropTypes.arrayOf(PropTypes.string),
  fill: PropTypes.bool,
  margins: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  svgHeight: PropTypes.number,
  svgWidth: PropTypes.number,
  xAxisMarkStart: PropTypes.bool,
  xAxisMarkEnd: PropTypes.bool,
  xAxisColor: PropTypes.string,
  yAxisMarksLeft: PropTypes.bool,
  yAxisMarksRight: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default ProjectionGraphWithSlider;
