import React, { Component } from 'react';
import { GraphWithSlider, GraphNumberSection } from '..';

class SubscribersUsersGraph extends Component {
  getDateString = date => {
    if (!date) return null;
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  render() {
    const { arrayRight } = this.props;
    return (
      <div
        className="subscribers-users__graph"
        data-test="subscribers-users-graph"
      >
        <p className="subscribers-users__date">
          {this.getDateString(this.props.date)}
        </p>
        <GraphNumberSection
          rightNumber={this.props.rightNumber}
          leftNumber={this.props.leftNumber}
          leftText={this.props.leftText}
          rightText={this.props.rightText}
          data-test="subscribers-users-stats"
        />
        <GraphWithSlider
          dataSets={[this.props.arrayRight, this.props.arrayLeft]}
          xAxisMarkStart={true}
          xAxisMarkEnd={
            arrayRight.data.length >= 2 &&
            !arrayRight.data[arrayRight.data.length - 1].isSameDay
          }
          fill={true}
          xAxisColor="#3a3b3e"
          svgWidth={320}
          svgHeight={175}
          graphColors={this.props.graphColors}
          onChange={this.props.getCurrentValues}
          data-test="subscribers-users-slider-graph"
          yAxisMarksLeft={this.props.yAxisMarksLeft}
          yAxisMarksRight={this.props.yAxisMarksRight}
        />
      </div>
    );
  }
}

export default SubscribersUsersGraph;
