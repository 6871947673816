import React from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import {
  createGraphDatasetGetterByMonth,
  getPayoutDataByMonth,
  getTotalRevenueByMonth,
} from '../../selectors';
import GraphWithSlider from '../graphWithSlider/GraphWithSlider';
import { ControlButton, ButtonRow } from '../controls';
import {
  StatAmount,
  StatAmountBlock,
  StatList,
  StatListItem,
  StatNumber,
  StatSection,
  StatTitle,
} from '../statBlocks';
import LinkToContact from '../linkToContact/LinkToContact';
import { GRAPHCOLORS, GRAPHNAMES } from '../../constants';

class BreakdownPage extends React.Component {
  constructor(props) {
    super(props);
    const { dataSets } = this.props;

    this.state = {
      width: 0,
      selectedKeys: this.props.defaultSelectedKeys,
      activeIndex:
        dataSets[0] && dataSets[0].data && dataSets[0].data.length - 1,
    };
  }

  setDims(options) {
    const { width, height, bottom } = options;
    this.setState({ width, height, bottom });
  }

  handleClick(selected) {
    const { selectedKeys } = this.state;
    let updatedKeys = selectedKeys;
    if (selectedKeys.includes(selected)) {
      updatedKeys = selectedKeys.filter(key => key !== selected);
    } else {
      updatedKeys.push(selected);
    }

    this.setState({ selectedKeys: updatedKeys });
  }

  render() {
    const { dataSets } = this.props;
    const selectedMatch = key => this.state.selectedKeys.includes(key);

    if (!dataSets[0]) return null;

    return (
      <>
        <div
          className={css`
            width: 100%;
            position: relative;
            box-sizing: border-box;
          `}
        >
          <ButtonRow label="Select a metric to display:">
            {dataSets.map(({ key }) => (
              <ControlButton
                key={key}
                color={GRAPHCOLORS[key]}
                active={this.state.selectedKeys.join('').includes(key)}
                onClick={() => this.handleClick(key)}
              >
                {GRAPHNAMES[key]}
              </ControlButton>
            ))}
          </ButtonRow>
          <StatSection>
            {dataSets[0].data[this.state.activeIndex] && (
              <StatList date={dataSets[0].data[this.state.activeIndex].date}>
                {dataSets
                  .filter(({ key }) => selectedMatch(key))
                  .map(({ key, data }) => (
                    <StatListItem key={key}>
                      <StatNumber color={GRAPHCOLORS[key]}>
                        {data[this.state.activeIndex].value}
                      </StatNumber>
                      {GRAPHNAMES[key]}
                    </StatListItem>
                  ))}
              </StatList>
            )}
            {this.props.showPayout && (
              <StatAmountBlock>
                <StatAmount>
                  {this.props.payoutAmounts.slice().reverse()[
                    this.state.activeIndex
                  ] || 0}
                </StatAmount>
                <StatTitle>Estimated Daily Revenue</StatTitle>
              </StatAmountBlock>
            )}
          </StatSection>
          <GraphWithSlider
            fill
            yAxisMarksLeft
            yAxisMarksRight
            xAxisMarkEnd
            xAxisMarkStart
            reportDims={this.setDims.bind(this)}
            dataSets={dataSets.filter(dataSet =>
              this.state.selectedKeys.join('').includes(dataSet.key)
            )}
            onChange={e =>
              e <= dataSets[0].data.length - 1 &&
              this.setState({ activeIndex: e })
            }
          />
          <div
            className={css`
              height: 1px;
              margin: 16px 16px 32px;
              background-color: #5a5c61;
            `}
          />
          <StatSection>
            {dataSets[0].data[dataSets[0].data.length - 1] && (
              <StatList
                date={dataSets[0].data[dataSets[0].data.length - 1].date}
              >
                {dataSets.map(({ key, data }) => (
                  <StatListItem key={key}>
                    <StatNumber color={GRAPHCOLORS[key]}>
                      {data[data.length - 1].value}
                    </StatNumber>
                    {GRAPHNAMES[key]}
                  </StatListItem>
                ))}
              </StatList>
            )}
            {this.props.showPayout && (
              <StatAmountBlock>
                <StatAmount fallback="Pending">
                  {this.props.totalRevenue
                    ? this.props.totalMonthlyRevenue
                    : this.props.payoutAmounts.slice().reverse()[
                        this.props.payoutAmounts.length - 1
                      ]}
                </StatAmount>
                <StatTitle>
                  {this.props.totalRevenue
                    ? 'Revenue Generated'
                    : 'Estimated Daily Revenue'}
                </StatTitle>
              </StatAmountBlock>
            )}
          </StatSection>
        </div>
        <div
          className={css`
            margin-top: 10px;
            margin-bottom: 45px;
          `}
        >
          <LinkToContact
            subject="finance"
            question="Question about your payouts?"
            firstName="the Accounting Team"
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const getGraphData = createGraphDatasetGetterByMonth(
    ownProps.date,
    state.influencerData
  );
  return {
    totalMonthlyRevenue: getTotalRevenueByMonth(state, ownProps.date),
    payoutAmounts: getPayoutDataByMonth(
      state.influencerData,
      ownProps.date
    ).map(datum => datum.earned_amount_cents),
    dataSets: ownProps.dataSetKeys.map(key => getGraphData(key)),
  };
};

export default connect(mapStateToProps)(BreakdownPage);
