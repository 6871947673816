import { combineReducers } from 'redux';
import user from './userReducer';
import data from './dataReducer';
import influencerData from './influencerDataReducer';
import loading from './loadingReducer';
import error from './errorReducer';
import contacts from './contactsReducer';

export default combineReducers({
  user,
  data,
  loading,
  error,
  contacts,
  influencerData,
});
