import React from 'react';
import { css } from 'emotion';

export const ButtonRow = ({ children, label }) => (
  <section
    className={css`
      color: #ffffff;
      font-family: Barlow;
      font-size: 12px;
      margin: 24px auto 0;
      max-width: 440px;
      padding: 0 16px;
    `}
  >
    <div>{label}</div>
    <div
      className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0.7em 0;
      `}
    >
      {children}
    </div>
  </section>
);
