import React, { Component } from 'react';
import { ProjectionGraphWithSlider, UserPayoutStats, LinkToContact } from '../';
import { css } from 'emotion';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { requestPayouts } from '../../actions/asyncActions';
import {
  projectedMonthlySubscribers,
  createLoadingSelector,
} from '../../selectors';
import './SubscribersGraph.scss';

const SubscribersNumberDisplay = props =>
  props.low ? (
    <React.Fragment>
      <span
        className={css`
          color: #f10364;
          line-height: 1;
          font-weight: bold;
          font-size: 32px;
          font-family: Barlow Condensed;
        `}
      >
        {props.low}
      </span>
      <span
        className={css`
          color: white;
          line-height: 1;
          font-weight: bold;
          font-size: 32px;
          font-family: Barlow Condensed;
          margin-left: 8px;
          margin-right: 8px;
        `}
      >
        -
      </span>
      <span
        className={css`
          color: #0076f9;
          line-height: 1;
          font-weight: bold;
          font-size: 32px;
          font-family: Barlow Condensed;
        `}
      >
        {props.high}
      </span>
    </React.Fragment>
  ) : (
    <span
      className={css`
        color: #0076f9;
        line-height: 1;
        font-weight: bold;
        font-size: 32px;
        font-family: Barlow Condensed;
      `}
    >
      {props.high}
    </span>
  );

class SubscribersGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribers: null,
      date: new Date(),
    };
  }

  componentDidMount() {
    if (!this.props.userData) {
      this.props.requestPayouts();
    } else {
      const {
        projectedLowSubscribers,
        projectedSubscribers,
      } = this.props.userData;
      this.setState({
        subscribers: {
          high:
            projectedSubscribers.data[projectedSubscribers.data.length - 1]
              .value,
          low:
            projectedLowSubscribers.data[
              projectedLowSubscribers.data.length - 1
            ].value,
        },
        date:
          projectedLowSubscribers.data[projectedLowSubscribers.data.length - 1]
            .date,
      });
    }
  }

  handleChange = output => {
    const {
      userData: { subscribers, projectedLowSubscribers, projectedSubscribers },
    } = this.props;
    let subscriberNumber;
    let date;
    if (output <= 14) {
      subscriberNumber = {
        high: subscribers.data[output].value,
      };
      date = subscribers.data[output].date;
    } else {
      subscriberNumber = {
        low: projectedLowSubscribers.data[output - 15].value,
        high: projectedSubscribers.data[output - 15].value,
      };
      date = projectedLowSubscribers.data[output - 15].date;
    }
    this.setState({
      subscribers: subscriberNumber,
      date,
    });
  };

  getDateString = date => {
    date = format(new Date(date), 'MMMM D');
    return date;
  };

  render() {
    const subscriberNumber = this.state.subscribers;
    const { projectedLowSubscribers, projectedSubscribers, subscribers } =
      this.props.userData || [];

    return (
      <div className="subscribers-graph__container">
        <p
          className={css`
            margin-bottom: -10px;
            text-transform: uppercase;
            font-family: Barlow;
            @media (min-width: 1199px) {
              display: none;
            }
          `}
        >
          {this.props.month}
        </p>
        <UserPayoutStats
          blockColorClasses={['number-blue', 'number-green', 'number-white']}
        />
        <div className="subscribers__graph" data-test="subscribers-graph">
          <span className="subscribers__date" data-test="subscribers-date">
            {this.getDateString(this.state.date)}
          </span>
          <div className="subscribers__number" data-test="subscribers-number">
            <SubscribersNumberDisplay {...subscriberNumber} />
            <div className="subscribers__number-text">SUBSCRIBERS</div>
          </div>
          <ProjectionGraphWithSlider
            dataSetsActual={[subscribers]}
            dataSetsProjection={[projectedSubscribers, projectedLowSubscribers]}
            onChange={this.handleChange}
            xAxisMarkStart={true}
            xAxisMarkEnd={true}
            fill={true}
            xAxisColor="#3a3b3e"
            data-test="subscribers-number-slider-graph"
            svgWidth={320}
            svgHeight={200}
          />
          <div
            className={css`
              display: flex;
              position: relative;
              margin-bottom: 15px;
            `}
            data-test="subscribers-legend"
          >
            <hr
              className={css`
                border: none;
                border-top: 1px dashed #0076f9;
                position: absolute;
                left: 20px;
                width: 50px;
                height: 1px;
              `}
            />
            <p
              className={css`
                margin: 0px;
                margin-left: 75px;
              `}
            >
              High Estimate
            </p>
          </div>
          <div
            className={css`
              display: flex;
              position: relative;
            `}
            data-test="subscribers-legend"
          >
            <hr
              className={css`
                border: none;
                border-top: 1px dashed #f10364;
                position: absolute;
                left: 20px;
                width: 50px;
                height: 1px;
              `}
            />
            <p
              className={css`
                margin: 0px;
                margin-left: 75px;
              `}
            >
              Low Estimate
            </p>
          </div>
        </div>
        <LinkToContact
          firstName="Accounting"
          subject="finance"
          question="Questions about your payouts?"
          data-test="subscribers-contact"
        />
      </div>
    );
  }
}

SubscribersGraph.defaultProps = {
  month: format(new Date(), 'MMMM'), // current month
};

const loadingSelector = createLoadingSelector(['PAYOUT_DATA']);
const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  userData: projectedMonthlySubscribers(state),
});

const mapDispatchToProps = {
  requestPayouts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribersGraph);
