import React, { Component } from 'react';
import './LoginPageForm.scss';
import PropTypes from 'prop-types';
import { Button } from '../';

const requiredFields = ['username', 'password'];

const formComplete = state => requiredFields.every(field => state[field]);

class LoginPageForm extends Component {
  constructor(props) {
    super(props);
    this.state = { username: '', password: '' };
  }

  onSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.state);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <form
        className="loginPageForm"
        onSubmit={
          formComplete(this.state) ? this.onSubmit : e => e.preventDefault()
        }
        id="form"
        data-test="loginPageForm"
      >
        <div className="loginPageForm__container">
          <input
            placeholder="Email"
            className="loginPageForm__container-input"
            data-test="loginPageForm-email"
            type="username"
            name="username"
            id="email"
            value={this.state.email}
            onChange={this.handleChange}
          />

          <input
            placeholder="Password"
            className="loginPageForm__container-input"
            data-test="loginPageForm-password"
            type="password"
            name="password"
            id="password"
            value={this.state.password}
            onChange={this.handleChange}
          />
        </div>
        <Button
          disabled={!formComplete(this.state)}
          data-test="loginPageForm-button"
          type="submit"
          id="login"
        >
          Login
        </Button>
      </form>
    );
  }
}

LoginPageForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default LoginPageForm;
