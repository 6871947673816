import produce from 'immer';
import actionTypes from '../actions/actionTypes';

const initialState = {
  // uncomment line below to bypass login
  // accessToken: true,
};

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return action.payload;
    case actionTypes.LOGOUT_SUCCESS:
      return {};
    default:
  }
}, initialState);
