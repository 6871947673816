import format from 'date-fns/format';
import getMonth from 'date-fns/get_month';
import addDays from 'date-fns/add_days';
import subDays from 'date-fns/sub_days';
import subMonths from 'date-fns/sub_months';
import addMinutes from 'date-fns/add_minutes';
import subMinutes from 'date-fns/sub_minutes';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import startOfMonth from 'date-fns/start_of_month';
import { GRAPHNAMES, GRAPHCOLORS } from '../constants';

const formatTimezone = date => {
  const offset = new Date(date).getTimezoneOffset();

  return Math.sign(offset) !== -1
    ? addMinutes(date, offset)
    : subMinutes(date, Math.abs(offset));
};

const formatCurrency = x => {
  if (!x) return null;
  return Math.round(x / 100)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getUserData = state => state.user;

export const createErrorMessageSelector = actions => ({ error }) => {
  // returns any error for a given action in an array of actions
  return actions.map(action => error[action]);
};

export const createLoadingSelector = actions => ({ loading }) => {
  // returns true only when all actions are not loading
  return actions.some(action => loading[action]);
};

export const activeUsersSelector = data => {
  if (!data) return null;
  const { current } = data;
  return current.daily.slice().pop().num_paid;
  // return current.num_paid;
};

export const activeUsersDifferenceSelector = data => {
  return monthlyDifference(data, 'num_paid', getLastDay);
  // return data.current.num_paid - data.history[0].num_paid;
};

export const expectedPayoutSelector = data => {
  if (!data) return null;
  const { current } = data;
  if (!(current && current.amount)) {
    return null;
  }
  return `$${formatCurrency(current.amount)}`;
};

export const expectedPayoutDifferenceSelector = data => {
  if (!data) return null;
  const { current } = data;
  const [previous] = data.history;
  if (!(current && current.amount) || !(previous && previous.amount)) {
    return 0;
  }
  return Math.round((current.amount - previous.amount) / 100);
};

export const nextPayoutDateSelector = data => {
  if (!data) return null;
  //do work here
};

export const totalSubsSelector = data => {
  const { current } = data;
  return current.num_joined;
};

export const totalLeftSelector = data => {
  const { current } = data;

  return current.num_left;
};

export const dailyTrialsSelector = ({ data }) => {
  if (!data) return null;

  const currentMonthData = data.current;
  const yesterdayData = getLastDay(currentMonthData);

  return yesterdayData && yesterdayData.num_new_trials;
};

export const dailyTrialsDifference = ({ data }) => {
  if (!data) return null;

  const daily = data.current.daily || [];
  const [twoDaysAgoData, yesterdayData] = daily.slice(daily.length - 2);

  if (!twoDaysAgoData || !yesterdayData) {
    return 0;
  }

  return yesterdayData.num_new_trials - twoDaysAgoData.num_new_trials;
};

export const monthlySubsSelector = ({ data }) => {
  if (!data) return null;
  const currentMonthData = data.current;
  return currentMonthData.num_joined;
};

export const monthlyTrialsSelector = ({ data }) => {
  if (!data) return null;
  const currentMonthData = data.current;

  return currentMonthData && currentMonthData.num_new_trials;
};

// const getFormattedDate = date => {
//   if (!date) return null;
//   return new Date(date.replace(/-/g, '/').replace(/T.+/, ''));
// };

export const dailyStatisticsArraySelector = ({ data }, dataVal) => {
  if (!data) return null;
  const currentMonth = data.current;
  const graphData = createGraphDatasetGetter(currentMonth.daily)(dataVal);

  if (graphData.data.length < 2) {
    //Array to short for graph generation need to use a projected value
    const [dayVal] = graphData.data;

    if (dayVal) {
      graphData.data = [
        dayVal,
        Object.assign({}, dayVal, {
          date: addDays(dayVal.date, 1),
          isSameDay: true,
        }),
      ];
    }
  }

  return graphData;
};

const getLastDay = data => (data.daily || []).slice().pop();

export const todayStatsSelector = ({ data }, dataVal) => {
  if (!data) return null;
  const daily = data.current.daily;
  const [dailyStats] = daily.slice(daily.length - 1);

  return dailyStats && dailyStats[dataVal];
};

const monthlyDifference = (data, key, cb) => {
  if (!data) return null;
  const currentMonthData = data.current;
  const [lastMonthData] = data.history; // assumes sorted reverse chron
  if (
    !(currentMonthData && currentMonthData[key]) ||
    !(lastMonthData && lastMonthData[key])
  ) {
    return 0;
  }
  if (cb) {
    return cb(currentMonthData)[key] - cb(lastMonthData)[key];
  }

  return currentMonthData[key] - lastMonthData[key];
};

export const monthlySubsDifference = ({ data }) => {
  return monthlyDifference(data, 'num_joined');
};

export const monthlyTrialsDifference = ({ data }) => {
  return monthlyDifference(data, 'num_new_trials');
};

export const payoutBlocksData = ({ data, influencerData }) => {
  if (!data) return null;

  const cleanData = data.history.map(d => {
    const month = d.month.split('T')[0];
    // Magic start date of the new data which comes from 'influencerData'
    const afterUpdate = isAfter(month, '2019-01-01');

    let lastDay = d.daily.slice().pop();

    if (afterUpdate) {
      const monthData = influencerData[month];
      lastDay = monthData && monthData[0];
    }

    return {
      numActiveSubs: lastDay
        ? afterUpdate
          ? lastDay.num_days
          : lastDay.num_paid
        : 0,
      month: format(formatTimezone(d.month), 'MMMM'),
      year: format(formatTimezone(d.month), 'YYYY'),
      amount: d.payout ? Math.round(d.payout / 100) : 0,
    };
  });

  return cleanData;
};

export const getTotalRevenueByMonth = ({ data }, month) => {
  if (data && !data.payouts) return null;

  const payout = data.payouts.find(p => p.month.split('T')[0] === month);

  return payout && payout.amount;
};

export const projectedMonthlySubscribers = ({ data }) => {
  if (!data) return null;

  const currentMonthData = data.current.daily;
  const lastMonthData = data.history[0].daily;
  const predictions = data.predictions;

  const dailySubHistory = [...lastMonthData, ...currentMonthData];

  const subscribers = createGraphDatasetGetter(
    dailySubHistory.slice(dailySubHistory.length - 15)
  )('num_paid');

  const projectedSubscribers = predictions.slice(0, 15);
  const getGraphData = createGraphDatasetGetter(projectedSubscribers);

  return {
    projectedSubscribers: getGraphData('num_paid_high'),
    projectedLowSubscribers: getGraphData('num_paid_low'),
    subscribers,
  };
};

export const getTargetMonth = (data, month) => {
  return data.history.find(
    d => d && getMonth(formatTimezone(d.month)) === getMonth(`${month}-1-2019`)
  );
};

export const historicalDataArraySelector = ({ data }, month, dataVal) => {
  if (!data) return null;
  const targetMonthData = getTargetMonth(data, month);
  return createGraphDatasetGetter(targetMonthData.daily)(dataVal);
};

export const endOfMonthStats = (state, month, dataVal, arrVal) => {
  if (!state.data) return null;
  const { data } = historicalDataArraySelector(state, month, dataVal);
  return data[data.length - 1][arrVal];
};

export const depositDaySelector = (state, month, dataVal) => {
  const lastDayOfMonth = endOfMonthStats(state, month, dataVal, 'date');
  return addDays(lastDayOfMonth, 1);
};

export const historicalTotalsSelector = ({ data }, month, dataVal) => {
  if (!data) return null;
  const lastDay = getTargetMonth(data, month)
    .daily.slice()
    .pop();
  return lastDay[dataVal];
};

export const getGraphDataByMonth = (
  data,
  key,
  month = format(startOfMonth(new Date()), 'YYYY-MM-DD')
) => {
  const selectedDatum = data[month] || Object.values(data)[0];
  const getGraphData = createGraphDatasetGetter(selectedDatum);

  return getGraphData(key);
};

export const getPayoutDataByMonth = (
  data,
  month = format(startOfMonth(new Date()), 'YYYY-MM-DD')
) => {
  let datum = data[month] || Object.values(data)[0];

  if (datum.length === 1) {
    const previousMonth = format(subMonths(month, 1), 'YYYY-MM-DD');
    const lastDayPreviousMonth = data[previousMonth][0];
    datum = [...datum, lastDayPreviousMonth];
  }
  return datum;
};

export const createGraphDatasetGetterByMonth = (month, data) => {
  // if (!data[month]) return () => null;

  let datum = data[month] || Object.values(data)[0];

  // If there is only 1 day in the month add the last day of the previous month
  // this ensures that all the graphs have the correct data.
  if (datum.length === 1) {
    const previousMonth = format(subMonths(month, 1), 'YYYY-MM-DD');
    const lastDayPreviousMonth = data[previousMonth][0];
    datum = [lastDayPreviousMonth, ...datum];
  }

  return createGraphDatasetGetter(datum);
};

export const createGraphDatasetGetter = dataArr => key => {
  let addedDays = [];
  if (dataArr.length === 1) {
    addedDays = dataArr.map(datum => ({
      value: datum[key],
      date: formatTimezone(subDays(datum.day, 1)),
    }));
  }

  return {
    key,
    name: GRAPHNAMES[key],
    color: GRAPHCOLORS[key],
    data: [
      ...addedDays,
      ...dataArr
        .map(datum => ({
          value: datum[key],
          date: formatTimezone(datum.day),
        }))
        .sort((a, b) => {
          if (isBefore(a.date, b.date)) return -1;
          if (isBefore(b.date, a.date)) return 1;
          return 0;
        }),
    ],
  };
};
