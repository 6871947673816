import React from 'react';
import Proptypes from 'prop-types';
import chroma from 'chroma-js';
import { css } from 'emotion';

export const ControlButton = ({ children, color, active, onClick }) => {
  const renderColor = active ? color : '#707071';
  const [red, green, blue] = chroma(renderColor).rgb();

  return (
    <button
      className={css`
        background-color: rgba(${red}, ${green}, ${blue}, 0.1);
        border: 1px solid ${renderColor};
        border-radius: 5px;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        flex: 1;
        min-height: 40px;
        padding: 0.4em 0.5em;
        margin: 1%;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &:focus {
          outline: none;
          border: 1px solid ${renderColor};
        }
      `}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

ControlButton.propTypes = {
  onClick: Proptypes.func,
  children: Proptypes.node,
  color: Proptypes.string,
  active: Proptypes.bool,
};

ControlButton.defaultProps = {
  onClick: () => null,
  color: '#fff',
  active: false,
  children: null,
};
