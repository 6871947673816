import React from 'react';
import PropTypes from 'prop-types';
import './stats-block.scss';

const StatsBlockSection = props => (
  <div className="stats-block__section">
    <span className="stats-block__number">{props.number}</span>
    <span className="stats-block__text">{props.text}</span>
    <div
      className={
        'stats-block__difference ' +
        (props.difference === 0
          ? ''
          : props.difference > 0
          ? 'positive'
          : 'negative')
      }
    >
      {props.difference && (
        <span>{`${
          props.difference > 0 ? `+${props.difference}` : props.difference
        } ${props.differenceText}`}</span>
      )}
    </div>
  </div>
);

export default StatsBlockSection;

StatsBlockSection.defaultProps = {
  differenceText: 'than last month',
};

StatsBlockSection.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  difference: PropTypes.number,
};
