import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import './sources/fonts/fonts_common.css';
import App from './components/app/App';
import store from './configureStore';
import { unregister } from './registerServiceWorker';

// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
// registerServiceWorker();
//Disabling service worker for now as it was causing
//issues with my netlify deployment
// if service worker exists unregister it.
unregister();
