import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'remote-redux-devtools';
import reduxThunk from 'redux-thunk';
import { loadState, saveState } from './helpers/sessionStorage';

import rootReducer from './reducers';

export const middleWares = [reduxThunk];

const store = createStore(
  rootReducer,
  loadState(),
  composeWithDevTools(applyMiddleware(...middleWares))
);

store.subscribe(() => {
  const { user, data, contacts, influencerData } = store.getState();
  saveState({
    user,
    data,
    contacts,
    influencerData,
  });
}); // throttle to 3 minutes between sessionStorage calls.

export default store;
