import React, { Component } from 'react';
import { Router } from '@reach/router';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/start_of_month';
import Loading from '../Loading';
import './App.css';

const DashboardPage = React.lazy(() =>
  import('../dashboardPage/DashboardPage')
);
const HistoryPage = React.lazy(() => import('../historyPage/HistoryPage'));
const CurrentPage = React.lazy(() => import('../currentPage/CurrentPage'));
const RevenuePage = React.lazy(() => import('../revenuePage/RevenuePage'));
const SubscribersPage = React.lazy(() =>
  import('../subscribersGraph/SubscribersPage')
);
const ContactPage = React.lazy(() => import('../contactPage/ContactPage'));
const StatDetailPage = React.lazy(() => import('../statDetailPage'));

/**
 * Class React component for the main application wrapper
 * @extends Component
 */
class App extends Component {
  render() {
    return (
      <React.StrictMode>
        <React.Suspense fallback={<Loading />}>
          <Router>
            <DashboardPage path="/" />
            <RevenuePage
              path="revenue"
              showPayout
              date={format(startOfMonth(new Date()), 'YYYY-MM-DD')}
              dataSetKeys={[
                'num_users_monthly',
                'num_users_quarter',
                'num_users_annual',
              ]}
              defaultSelectedKeys={[
                'num_users_monthly',
                'num_users_quarter',
                'num_users_annual',
              ]}
            />
            <CurrentPage
              path="current"
              date={format(startOfMonth(new Date()), 'YYYY-MM-DD')}
              dataSetKeys={['num_days', 'num_starts', 'num_ends', 'num_trials']}
              defaultSelectedKeys={['num_days']}
            />
            <HistoryPage path="month/:month" />
            <ContactPage path="contact" />
            <ContactPage path="contact/:contactId" />
          </Router>
        </React.Suspense>
      </React.StrictMode>
    );
  }
}

export default App;
