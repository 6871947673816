import produce from 'immer';

export const merge = produce(Object.assign);

export const pick = (obj, pickArr) =>
  Object.entries(obj).reduce(
    (acc, [key, val]) =>
      Object.assign(acc, pickArr.includes(key) && { [key]: val }),
    {}
  );

export const omit = (obj, omitArr) =>
  Object.entries(obj).reduce(
    (acc, [key, val]) =>
      Object.assign(acc, !omitArr.includes(key) && { [key]: val }),
    {}
  );
