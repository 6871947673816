import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Link } from '@reach/router';
import './dashboard-subscribers.scss';
import { StatsBlockSection } from '..';

const DashboardSubscribersBlocks = props => {
  return (
    <Link
      to="/current"
      className="dashboard-subscribers__container"
      data-test="dashboard-subscribers"
      style={{ textDecoration: 'none' }}
    >
      <div className="dashboard-subscribers__block" data-test="container">
        <StatsBlockSection
          number={props.subscribers}
          text="Change in Subscribers"
          data-test="container-subscribers"
        />
        {/*
          difference={props.subscribersDifference}
        */}
        <div className="dashboard-subscribers__divider" />
        <StatsBlockSection
          number={props.trialUsers}
          text="New Users On Trial"
          difference={props.trialUsersDifference}
          data-test="container-trial"
        />
      </div>
      <div
        className={
          'dashboard-subscribers__block-desktop ' +
          css`
            ${props.active
              ? 'border: solid 1px #00ffb3;'
              : 'border: solid 1px #000'};
          `
        }
        data-test="dashboard-subscribers"
        style={{ textDecoration: 'none' }}
        tabIndex="0"
        role="button"
      >
        <StatsBlockSection
          number={props.subscribers}
          text="Change in Subscribers"
          data-test="container-subscribers"
        />
        {/* difference={props.subscribersDifference}*/}
        <div className="dashboard-subscribers__divider" />
        <StatsBlockSection
          number={props.trialUsers}
          text="New Users On Trial"
          difference={props.trialUsersDifference}
          differenceText="than yesterday"
          data-test="container-trial"
        />
      </div>
    </Link>
  );
};

DashboardSubscribersBlocks.propTypes = {
  numberTrial: PropTypes.number,
  numberSubscribers: PropTypes.number,
  monthSubscribersDifference: PropTypes.number,
  dayUsersOnTrialDifference: PropTypes.number,
};

export default DashboardSubscribersBlocks;
