import React from 'react';
import { Link } from '@reach/router';
import './FitplanLogo.scss';

const FitplanLogo = () => (
  <Link to="/" className="fitplan-logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="38"
      viewBox="0 0 46 38"
    >
      <g fill="#FFF">
        <path d="M2.308 27.096L0 37.982h12.752l2.308-10.886zM5.171 13.548L2.863 24.434h25.029L30.2 13.548zM8.064 0L5.755 10.887h37.507L45.57 0z" />
      </g>
    </svg>
  </Link>
);

export default FitplanLogo;
