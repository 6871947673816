import React from 'react';
import { Link } from '@reach/router';
import { css } from 'emotion';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/fa/chevronRight';

export const ContentCard = ({
  active,
  children,
  navigationText,
  navigateTo,
}) => (
  <Link
    to={navigateTo}
    className={css`
      color: #fff;
      text-decoration: none;
    `}
  >
    <section
      className={css`
        margin: 0 auto 16px auto;
        border-radius: 10px;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
        width: 100%;
        background-color: #3a3b3e;
        display: flex;
        flex-direction: column;
        @media (min-width: 1199px) {
          margin: 0 0 16px 0;
          max-width: 404px;
        }
        ${active ? 'border: solid 1px #00ffb3' : ''}
      `}
    >
      <div
        className={css`
          padding: 16px 16px 8px 16px;
        `}
      >
        {children}
      </div>
      <div
        className={css`
          padding: 12px 13px;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          display: flex;
          justify-content: flex-end;
          align-items: center;
        `}
      >
        <span
          className={css`
            margin: 5px;
            font-family: Barlow;
            font-size: 12px;
          `}
        >
          {navigationText}
        </span>
        <Icon icon={chevronRight} size={10} />
      </div>
    </section>
  </Link>
);
