import React from 'react';
import { css } from 'emotion';
import { Spinner } from '../';

const Loading = () => (
  <div
    className={css`
      width: 100vh;
      hight: 100vh;
      background-color: black;
    `}
  >
    <Spinner active />
  </div>
);

export default Loading;
