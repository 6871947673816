import React from 'react';
import { css } from 'emotion';

const Button = ({ children, disabled, ...props }) => (
  <button
    className={css`
      height: 60px;
      width: 50%;
      margin: auto;
      border-radius: 100px;
      background-image: linear-gradient(74deg, #00bd98, #28d95a);
      font-size: 21px;
      font-weight: bold;
      font-family: 'Barlow Condensed', sans-serif;
      text-transform: uppercase;
      color: #fff;
      border: none;
      cursor: pointer;
      ${disabled
        ? `
          background-image: none;
          background-color: #CCCCCC
        `
        : ''};
    `}
    {...props}
  >
    {children}
  </button>
);

export default Button;
