import React from 'react';
import format from 'date-fns/format';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { requestPayouts } from '../../actions/asyncActions';
import { calendar } from 'react-icons-kit/entypo/calendar';
import { Icon } from 'react-icons-kit';
import {
  dailyTrialsSelector,
  dailyTrialsDifference,
  activeUsersDifferenceSelector,
  monthlySubsSelector,
  monthlySubsDifference,
  getGraphDataByMonth,
  getPayoutDataByMonth,
} from '../../selectors';

import { GridPayoutBlocks, LinkToContact } from '../';

import { UsersReportBlock } from '../usersReportBlock';
import { RevenueReportBlock } from '../revenueReportBlock';
import './DashboardNav.scss';

class DashboardNav extends React.Component {
  componentDidMount() {
    if (!this.props.hasData) {
      this.props.requestPayouts();
    }
  }

  render() {
    if (!this.props.hasData) return null;

    const { location = {} } = this.props;
    const { pathname = '' } = location;

    return (
      <div className="desktop__page" data-test="desktop-page">
        <h1 className="desktop__title" data-test="title">
          {this.props.title}
        </h1>
        <h4 className="subscribers__blocks-month" data-test="block-month">
          <Icon icon={calendar} className="blocks-month__icon" />
          {this.props.date}
        </h4>
        {this.props.dailyPaidUsers && (
          <UsersReportBlock
            dailyPaidUsers={this.props.dailyPaidUsers}
            active={pathname === '/' || pathname.includes('current')}
          />
        )}
        {this.props.payoutData && this.props.payoutData.length > 0 ? (
          <RevenueReportBlock
            active={pathname.includes('revenue')}
            payoutData={this.props.payoutData}
          />
        ) : (
          <h2>No Revenue Data Available Yet</h2>
        )}
        <span
          className={css`
            margin-top: -10px;
            margin-bottom: 15px;
          `}
          data-test="user-payout-asterisk"
        >
          *based on current numbers
        </span>
        <h4 className="subscribers__blocks-payout" data-test="block-payout">
          PAYOUT HISTORY
        </h4>
        <GridPayoutBlocks
          activeMonth={
            pathname.includes('month') ? pathname.split('/').pop() : null
          }
        />
        <div
          className={css`
            @media (min-width: 1199px) {
              display: none;
            }
          `}
        >
          <LinkToContact
            firstName="Accounting"
            subject="finance"
            question="Questions about your payouts?"
            data-test="block-contact"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const dailyPaidUsers = getGraphDataByMonth(state.influencerData, 'num_days');

  return {
    trialUsers: dailyTrialsSelector(state),
    trialUsersDifference: dailyTrialsDifference(state),
    subscribers: monthlySubsSelector(state),
    subscribersDifference: monthlySubsDifference(state),
    hasData: state.data && Object.keys(state.data).length > 0,
    activeUsersDifference: activeUsersDifferenceSelector(state.data),
    payoutData: getPayoutDataByMonth(state.influencerData),
    dailyPaidUsers,
    date:
      dailyPaidUsers.data[dailyPaidUsers.data.length - 1] &&
      format(
        dailyPaidUsers.data[dailyPaidUsers.data.length - 1].date,
        'MMM DD'
      ), // current month
  };
};

const mapDispatchToProps = {
  requestPayouts,
};

DashboardNav.defaultProps = {
  title: 'Dashboard',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardNav);
