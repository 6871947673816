import React, { Component } from 'react';
import { Location } from '@reach/router';
import format from 'date-fns/format';
import MediaQuery from 'react-responsive';
import { Auth, Layout, Spinner } from '../';
import DashboardNav from '../dashboardNav/DashboardNav';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../selectors';

class Page extends Component {
  static defaultProps = {
    month: format(new Date(), 'MMMM'),
  };

  render() {
    return (
      <Location>
        {({ location }) => (
          <Layout data-test="dashboard-page">
            <Auth>
              <Spinner active={this.props.isFetching} />
              {!this.props.isFetching && (
                <React.Fragment>
                  <MediaQuery maxWidth={1199}>{this.props.children}</MediaQuery>
                  <MediaQuery minWidth={1200}>
                    {this.props.noNav ? (
                      this.props.children
                    ) : (
                      <div
                        className={css`
                          display: grid;
                          grid-template-columns: 50% 50%;
                          padding: 16px;
                        `}
                      >
                        <div
                          className={css`
                            width: 60%;
                            margin-left: auto;
                          `}
                        >
                          <DashboardNav location={location} />
                        </div>
                        <div
                          className={css`
                            width: 60%;
                            margin-left: 10px;
                            border: 1px solid #00ffb3;
                            border-radius: 10px;
                            margin-top: 45px;
                            min-height: 555px;
                            max-width: 414px;
                          `}
                        >
                          {this.props.children}
                        </div>
                      </div>
                    )}
                  </MediaQuery>
                </React.Fragment>
              )}
            </Auth>
          </Layout>
        )}
      </Location>
    );
  }
}

const loadingSelector = createLoadingSelector(['PAYOUT_DATA']);
const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
});

export default connect(mapStateToProps)(Page);
