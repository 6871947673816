import React, { Component } from 'react';
import isBefore from 'date-fns/is_before';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { SubscribersUsersGraph, GraphNumberSection, LinkToContact } from '..';
import StatDetailPage from '../statDetailPage';
import {
  historicalDataArraySelector,
  historicalTotalsSelector,
  depositDaySelector,
  getTargetMonth,
} from '../../selectors';
import { requestPayouts } from '../../actions/asyncActions';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '../tabs';

const START_DATE_FOR_NEW_VERSION = '2019-01-01';

class History extends Component {
  state = {
    activeIndex: this.props.trialUsers.data.length - 1,
  };

  getCurrentValues = output => {
    this.setState({ activeIndex: output });
  };

  render() {
    const totalPayout = Math.round(this.props.totalPayout / 100);
    const { activeIndex } = this.state;
    return !isBefore(
      this.props.targetMonth.month,
      START_DATE_FOR_NEW_VERSION
    ) ? (
      <Tabs>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Breakdown</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <StatDetailPage
              key="stats"
              path="current"
              showPayout
              totalRevenue
              date={format(
                this.props.targetMonth.month.split('T')[0],
                'YYYY-MM-DD'
              )}
              dataSetKeys={['num_days', 'num_starts', 'num_ends', 'num_trials']}
              defaultSelectedKeys={['num_starts', 'num_ends', 'num_trials']}
            />
          </TabPanel>
          <TabPanel>
            <StatDetailPage
              key="revenue"
              showPayout
              totalRevenue
              date={format(
                this.props.targetMonth.month.split('T')[0],
                'YYYY-MM-DD'
              )}
              dataSetKeys={[
                'num_users_monthly',
                'num_users_quarter',
                'num_users_annual',
              ]}
              defaultSelectedKeys={[
                'num_users_monthly',
                'num_users_quarter',
                'num_users_annual',
              ]}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    ) : (
      <div className="history-page__container" data-test="history-page">
        <p className="history-page__month">{this.props.month}</p>

        <GraphNumberSection
          leftText="Total Subscribers"
          rightText="Users on Trial"
          leftNumber={this.props.totalSubscribers}
          rightNumber={this.props.totalTrails}
          payout={totalPayout}
          payoutText="earned*"
          data-test="history-page-stats"
        />
        <hr className="history-page__divider" />
        <SubscribersUsersGraph
          rightNumber={this.props.trialUsers.data[activeIndex].value}
          leftNumber={Math.trunc(
            this.props.subscribers.data[activeIndex].value
          )}
          date={this.props.trialUsers.data[activeIndex].date}
          getCurrentValues={this.getCurrentValues}
          arrayRight={this.props.subscribers}
          arrayLeft={this.props.trialUsers}
          graphColors={['#00ffb3', '#0076f9']}
          rightText="New Users on trial"
          leftText="New Subscribers"
          data-test="history-page-graph"
          yAxisMarksLeft
          yAxisMarksRight
        />
        <LinkToContact
          firstName="Accounting"
          subject="finance"
          question="Questions about your payouts?"
          data-test="history-page-link"
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: state.data,
  targetMonth: getTargetMonth(state.data, ownProps.month),
  trialUsers: historicalDataArraySelector(
    state,
    ownProps.month,
    'num_new_trials'
  ),
  subscribers: historicalDataArraySelector(state, ownProps.month, 'num_joined'),
  month: ownProps.month,
  totalTrails: historicalTotalsSelector(state, ownProps.month, 'num_trials'),
  totalSubscribers: historicalTotalsSelector(state, ownProps.month, 'num_paid'),
  totalPayout: historicalTotalsSelector(state, ownProps.month, 'amount'),
  depositDay: depositDaySelector(state, ownProps.month, 'num_paid'),
});

const mapDispatchToProps = {
  requestPayouts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History);
