import React from 'react';
import format from 'date-fns/format';
import { ContentCard } from '../contentCard';
import { StatNumber, StatDifference } from '../statBlocks';
import Graph from '../graph/Graph';
import { GRAPHNAMES } from '../../constants';

const getDiff = (current, previous, key) => {
  if (!previous) return 0;
  return current.value - previous.value;
};

export const UsersReportBlock = props => {
  const [mostRecent, previousDay] = props.dailyPaidUsers.data.slice().reverse();

  return (
    <ContentCard
      active={props.active}
      navigationText="See Users Report"
      navigateTo="/current"
    >
      <div>
        {mostRecent && <StatNumber size={32}>{mostRecent.value}</StatNumber>}
        {previousDay && (
          <StatDifference
            diff={getDiff(mostRecent, previousDay)}
            date={format(previousDay.date, 'MMM DD')}
          />
        )}
      </div>
      <div>{GRAPHNAMES['num_days']}</div>
      <div>
        <Graph
          name="user-report-graph"
          dataSets={[props.dailyPaidUsers]}
          fill
          maxHeight={80}
          margins={{ left: 20, right: 20, top: 20, bottom: 20 }}
          xAxisMarkStart
          xAxisMarkEnd
        />
      </div>
    </ContentCard>
  );
};
