import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loginUser } from '../../actions/asyncActions';
import { createLoadingSelector } from '../../selectors';
import LoginPage from '../loginPage/LoginPage';

class Auth extends Component {
  render() {
    return this.props.loggedIn ? (
      this.props.children
    ) : (
      <LoginPage
        onSubmit={this.props.onSubmit}
        isFetching={this.props.isFetching}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

const loadingSelector = createLoadingSelector(['LOGIN']);
const mapStateToProps = state => ({
  loggedIn: !!state.user.accessToken,
  isFetching: loadingSelector(state),
  errorMessage: state.error.LOGIN,
});

const mapDispatchToProps = {
  onSubmit: user => loginUser(user),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
