import { logoutSuccess, logoutFailure } from '.';
import { removeState } from '../helpers/sessionStorage';

export const logoutUser = () => {
  return dispatch => {
    //delete from local storage
    if (!removeState()) {
      dispatch(logoutFailure({ message: 'Error clearing local storage' }));
    }
    dispatch(logoutSuccess());
  };
};
