import React from 'react';
import { Page } from '../';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/start_of_month';
import './DashboardPage.scss';
import MediaQuery from 'react-responsive';
import DashboardNav from '../dashboardNav/DashboardNav';
const StatDetailPage = React.lazy(() => import('../statDetailPage'));

const DashboardPage = () => (
  <Page>
    <MediaQuery maxWidth={1199}>
      <DashboardNav />
    </MediaQuery>
    <MediaQuery minWidth={1200}>
      <StatDetailPage
        dataSetKeys={['num_days', 'num_starts', 'num_ends', 'num_trials']}
        defaultSelectedKeys={['num_days']}
        date={format(startOfMonth(new Date()), 'YYYY-MM-DD')}
      />
    </MediaQuery>
  </Page>
);

export default DashboardPage;
