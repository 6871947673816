import React from 'react';
import './ContactPersonPage.scss';
import { Spinner } from '../';
import { Icon } from 'react-icons-kit';
import { check } from 'react-icons-kit/fa/check';

/**
 * Functional React component for ContactPersonPage Component: top menu page wrapper
 * @function
 * @param {objext} props - React props: children JSX.Element <PageComponent>
 * @returns {JSX.Element}
 */

const spinnerPosition = {
  marginBottom: '100px',
};

const ContactPersonPage = ({
  image,
  firstName,
  lastName,
  jobTitle,
  services,
  email,
  back,
}) => {
  if (!image)
    return (
      <div style={spinnerPosition}>
        <Spinner active={true} />
      </div>
    );
  console.log(email);
  return (
    <div
      className="contact-person-page-wrapper"
      data-test="contact-person-page-wrapper"
    >
      <div className="contact-person-header-section">
        <div className="contact-person-avatar">
          <img src={image} alt={`${firstName} ${lastName}`} />
        </div>
        <div className="contact-person-name-title">
          <p className="contact-person-name">
            {firstName} {lastName}
          </p>
          <p className="contact-person-title">{jobTitle}</p>
        </div>
      </div>
      <p className="contact-person-sub-title">
        {firstName === 'Accounting' ? 'The ' : ''}
        {firstName} {lastName} is your contact for all business relating to:
      </p>
      <ul className="contact-person-checklist">
        {services &&
          services.map((listItem, index) => {
            return (
              <li key={index}>
                <span className="contact-person-checklist-item">
                  <Icon
                    className="contact-person-checklist-icon"
                    icon={check}
                  />
                  {listItem}
                </span>
              </li>
            );
          })}
      </ul>
      <a
        className="contact-person-email-button"
        href={`mailto:${email}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Email {firstName}
      </a>
    </div>
  );
};

export default ContactPersonPage;
