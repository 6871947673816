import React from 'react';
import PropTypes from 'prop-types';
import './GraphWithSlider.css';
import { pick, merge } from '../../helpers/objectUtil.js';
import { Graph, RangeSlider } from '../';

const graphKeys = [
  'dataSets',
  'graphColors',
  'fill',
  'margins',
  'xAxisMarkStart',
  'xAxisMarkEnd',
  'xAxisColor',
  'yAxisMarksLeft',
  'yAxisMarksRight',
];

const sliderKeys = ['onChange', 'value', 'min', 'max'];

class GraphWithSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
  }

  setDims(options) {
    const { width, height, bottom } = options;
    this.setState({ width, height, bottom });
  }

  render() {
    const graphProps = pick(this.props, graphKeys);
    const sliderProps = merge(pick(this.props, sliderKeys), {
      points:
        graphProps.dataSets &&
        graphProps.dataSets[0] &&
        graphProps.dataSets[0].data.length,
    });

    return (
      <div
        data-test="component-graph-with-slider"
        className="graph-with-slider"
      >
        <Graph {...graphProps} reportDims={this.setDims.bind(this)} />
        <RangeSlider
          {...sliderProps}
          width={this.state.width}
          height={this.state.height}
          bottom={this.state.bottom}
        />
      </div>
    );
  }
}

GraphWithSlider.propTypes = {
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.instanceOf(Date).isRequired,
          value: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  fill: PropTypes.bool,
  margins: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  svgHeight: PropTypes.number,
  svgWidth: PropTypes.number,
  xAxisMarkStart: PropTypes.bool,
  xAxisMarkEnd: PropTypes.bool,
  xAxisColor: PropTypes.string,
  yAxisMarksLeft: PropTypes.bool,
  yAxisMarksRight: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default GraphWithSlider;
