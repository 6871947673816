import React from 'react';
import { Link } from '@reach/router';
import './MenuList.scss';
import { FitplanLogo } from '../';

const listStyle = {
  textDecoration: 'none',
  color: '#fff',
};

const MenuList = props => {
  return (
    <div className="menu-list__container" data-test="menu-list">
      <ul>
        <li data-test="list-items" className="menu-list__logo">
          <FitplanLogo />
        </li>
        <li data-test="list-items">
          <Link style={listStyle} to="/">
            <p>Dashboard</p>
          </Link>
        </li>
        <li data-test="list-items">
          <Link style={listStyle} to="/contact">
            <p>Contact</p>
          </Link>
        </li>
        <li data-test="list-items">
          <p onClick={() => props.logoutUser()} style={listStyle}>
            Logout
          </p>
        </li>
      </ul>
    </div>
  );
};

export default MenuList;
