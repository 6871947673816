import React, { Component } from 'react';
import {
  BurgerButton,
  BackArrow,
  MenuList,
  FitplanFullLogo,
  FitplanLogoWide,
} from '../';
import { logoutUser } from '../../actions/logoutActions';
import { connect } from 'react-redux';
import { css } from 'emotion';
import './Nav.scss';

/**
 * Functional React component for Nav Component: top menu page wrapper
 * @function
 * @param {objext} props - React props: children JSX.Element <PageComponent>
 * @returns {JSX.Element}
 */

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      back: false,
    };
  }

  previousPage = () => {
    return window.history.back();
  };

  handleToggle = () =>
    this.setState(state => Object.assign({}, state, { open: !state.open }));

  render() {
    return (
      <div data-test="header">
        {this.props.loggedIn ? (
          <header className="header" data-test="header-container">
            <div className="header__mobile">
              <div
                className={
                  this.state.open
                    ? 'header-container open'
                    : css`
                        margin: auto;
                        width: 100%;
                        position: relative;
                      `
                }
              >
                <div
                  className={
                    this.state.open
                      ? css`
                          display: none;
                        `
                      : css`
                          width: 100%;
                        `
                  }
                >
                  <BackArrow back={this.previousPage} />
                  <div className="header-title" data-test="header-title">
                    <FitplanFullLogo />
                  </div>
                </div>
                <div
                  className={
                    this.state.open
                      ? 'menu-list open'
                      : css`
                          display: none;
                        `
                  }
                >
                  <MenuList logoutUser={this.props.logoutUser} />
                </div>
                <div
                  className={
                    this.state.open
                      ? 'burger-button open'
                      : 'burger-button close'
                  }
                >
                  <BurgerButton
                    open={this.state.open}
                    onClick={this.handleToggle}
                  />
                </div>
              </div>
            </div>
            <div className="header__web">
              <div className="header__web-logo">
                <div className="header-title" data-test="header-title">
                  <FitplanLogoWide />
                </div>
              </div>
              <MenuList logoutUser={this.props.logoutUser} />
            </div>
          </header>
        ) : (
          <header
            className={css`
              min-height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <div
              className={css`
                width: 200px;
              `}
            >
              <FitplanLogoWide />
            </div>
          </header>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: !!state.user.accessToken,
});

const mapDispatchToProps = {
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nav);
